import React from 'react';
import { useState } from 'react';
import { Row, Col, Form, Modal, Button, InputGroup } from 'react-bootstrap';
import { ToTriggerJsonFormEvent } from '../utils/helperTriggers';

interface AddTriggerProps {
  showAddModal: boolean;
  handleClose: () => void;
  onFormSubmitAddTrigger: (event: ToTriggerJsonFormEvent) => Promise<void>;
  triggerCatName: string;
  premiumLevel: number;
  domainName: string;
}

export function AddTrigger(props: AddTriggerProps): JSX.Element {
  const [isCeiling1Enabled, setIsCeiling1Enabled] = useState(false);
  const [isCeiling2Enabled, setIsCeiling2Enabled] = useState(false);
  const [isCeiling3Enabled, setIsCeiling3Enabled] = useState(false);
  const [isCeilingKeepaEnabled, setIsCeilingKeepaEnabled] = useState(false);
  const [isMMEnabled, setIsMMEnabled] = useState(false);

  function handleC1Change(event: React.ChangeEvent<HTMLInputElement>): void {
    setIsCeiling1Enabled(event.target.checked);
  }
  function handleC2Change(event: React.ChangeEvent<HTMLInputElement>) {
    setIsCeiling2Enabled(event.target.checked);
  }
  function handleC3Change(event: React.ChangeEvent<HTMLInputElement>) {
    setIsCeiling3Enabled(event.target.checked);
  }
  function handleCKeepaChange(event: React.ChangeEvent<HTMLInputElement>) {
    setIsCeilingKeepaEnabled(event.target.checked);
  }

  function handleOpen(): void {
    setIsMMEnabled(false);
    setIsCeilingKeepaEnabled(false);
    setIsCeiling1Enabled(false);
    setIsCeiling2Enabled(false);
    setIsCeiling3Enabled(false);
  }

  return (
    <Modal onEntered={handleOpen} show={props.showAddModal} onHide={props.handleClose} dialogClassName="editModal">
      <Form onSubmit={props.onFormSubmitAddTrigger}>
        <Modal.Header closeButton>
          <Form.Control
            required
            type="text"
            name="triggerName"
            className="input-text-trigger-name"
            placeholder="Enter Trigger Name Here"
          />
        </Modal.Header>
        <Modal.Body>
          <div style={{ fontSize: '13px', fontWeight: '500' }}>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formMinScore">
                      <Form.Label>Min eScore</Form.Label>
                      <Form.Control required type="number" step="0.01" name="minEscore" placeholder="Min eScore" />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formMaxScore">
                      <Form.Label>Max eScore</Form.Label>
                      <Form.Control required type="number" step="0.01" name="maxEscore" placeholder="Max eScore" />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formMinRank">
                      <Form.Label>Min SalesRank</Form.Label>
                      <Form.Control required type="number" step="0.01" name="minRank" placeholder="Min SalesRank" />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formMaxRank">
                      <Form.Label>Max SalesRank</Form.Label>
                      <Form.Control required type="number" step="0.01" name="maxRank" placeholder="Max SalesRank" />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formFbaSlot">
                      <Form.Label>FBA Slot</Form.Label>

                      <Form.Control required as="select" name="fbaSlot">
                        <option value="-1">SKIP</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </Form.Control>

                      {/* <Form.Control
                          required
                          type="number"
                          step="0.01"
                          name="fbaSlot"
                          placeholder="FBA Slot"
                        />
                        <Form.Text className="text-muted">
                          Enter <strong>-1</strong> to disable.
                        </Form.Text> */}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    {props.triggerCatName.includes('_new_') ? (
                      <Form.Group controlId="formUsedSlot">
                        <Form.Label>New Slot</Form.Label>
                        <Form.Control required as="select" name="newSlot">
                          <option value="-1">SKIP</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">10</option>
                          <option value="7">15</option>
                        </Form.Control>
                      </Form.Group>
                    ) : (
                      <Form.Group controlId="formUsedSlot">
                        <Form.Label>Used Slot</Form.Label>
                        <Form.Control required as="select" name="usedSlot">
                          <option value="-1">SKIP</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">10</option>
                          <option value="7">15</option>
                        </Form.Control>
                      </Form.Group>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formAmzPer">
                      <Form.Label>Amazon Off %</Form.Label>
                      {/* <Form.Control required type="number" step="0.01" name="amzOffPer" placeholder="Amazon Off %" /> */}
                      <InputGroup className="mb-3">
                        <Form.Control required type="number" step="0.01" name="amzOffPer" />
                        <InputGroup>
                          <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                        </InputGroup>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formTargetProfit">
                      <Form.Label>Target Profit</Form.Label>

                      <InputGroup>
                        <InputGroup>
                          <InputGroup.Text
                            style={{
                              background: '#007bff',
                              color: '#fff',
                              fontWeight: 'bold',
                            }}
                            id="per-symbol"
                          >
                            $
                          </InputGroup.Text>
                        </InputGroup>
                        <Form.Control
                          style={{
                            background: '#007bff',
                            color: '#fff',
                            fontWeight: 'bold',
                          }}
                          required
                          type="number"
                          step="0.01"
                          name="targetProfit"
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>

                <Row style={{ height: '50px' }}>
                  <Col style={{ paddingTop: '8px' }} md={3}>
                    <Form.Group controlId="formIsCeiling1Compare">
                      <Form.Check
                        onChange={handleC1Change}
                        type="switch"
                        name="isCeiling1Enabled"
                        id="is-ceiling-1"
                        label="Ceiling 1?"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    {isCeiling1Enabled && (
                      <Form.Group controlId="formCeiling1Options">
                        <Form.Control as="select" name="ceiling1Options">
                          <option>...</option>
                          <option>Lowest New Price</option>
                          <option>New Buy Box</option>
                        </Form.Control>
                      </Form.Group>
                    )}
                  </Col>
                  <Col md={5}>
                    {isCeiling1Enabled && (
                      <InputGroup>
                        <Form.Control required type="number" step="0.01" name="ceiling1Discount" />
                        <InputGroup>
                          <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                        </InputGroup>
                      </InputGroup>
                    )}
                  </Col>
                </Row>

                <Row style={{ height: '50px' }}>
                  <Col style={{ paddingTop: '8px' }} md={2}>
                    <Form.Group controlId="formIsCeiling3Compare">
                      <Form.Check
                        onChange={handleC3Change}
                        type="switch"
                        name="isCeiling3Enabled"
                        id="is-ceiling-3"
                        label="PrimeLess?"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    {isCeiling3Enabled && (
                      <Form.Group controlId="formCeiling3Options">
                        <Form.Control style={{ fontSize: '13px' }} as="select" name="ceiling3Options">
                          <option>...</option>
                          <option>Lowest Used Offer</option>
                          <option>2nd Lowest Used Offer</option>
                          <option>3rd Lowest Used Offer</option>
                          <option>Average of 3 Used Offers</option>
                        </Form.Control>
                      </Form.Group>
                    )}
                  </Col>
                  <Col md={3}>
                    {isCeiling3Enabled && (
                      <InputGroup>
                        <InputGroup>
                          <InputGroup.Text id="per-symbol">$</InputGroup.Text>
                        </InputGroup>
                        <Form.Control
                          required
                          type="number"
                          step="0.01"
                          name="ceiling3BumpUpDollars"
                          placeholder="Bump$"
                        />
                      </InputGroup>
                    )}
                  </Col>
                  <Col md={3}>
                    {isCeiling3Enabled && (
                      <InputGroup>
                        <Form.Control
                          required
                          type="number"
                          step="0.01"
                          name="ceiling3BumpUpPercentage"
                          placeholder="Bump%"
                        />
                        <InputGroup>
                          <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                        </InputGroup>
                      </InputGroup>
                    )}
                  </Col>
                </Row>

                <Row style={{ height: '50px' }}>
                  <Col style={{ paddingTop: '8px', paddingRight: '0' }} md={2}>
                    <Form.Group controlId="formIsCeiling2Compare">
                      <Form.Check
                        onChange={handleC2Change}
                        type="switch"
                        name="isCeiling2Enabled"
                        id="is-ceiling-2"
                        label="Ceiling 2?"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    {isCeiling2Enabled && (
                      <Form.Group controlId="formCeiling2Options">
                        <Form.Control style={{ fontSize: '13px' }} as="select" name="ceiling2Options">
                          <option>...</option>
                          <option>Lowest Used Offer</option>
                          <option>2nd Lowest Used Offer</option>
                          <option>3rd Lowest Used Offer</option>
                          <option>Average of 3 Used Offers</option>
                        </Form.Control>
                      </Form.Group>
                    )}
                  </Col>
                  <Col md={3}>
                    {isCeiling2Enabled && (
                      <InputGroup>
                        <InputGroup>
                          <InputGroup.Text id="per-symbol">$</InputGroup.Text>
                        </InputGroup>
                        <Form.Control
                          placeholder="Bump$"
                          required
                          type="number"
                          step="0.01"
                          name="ceiling2BumpUpDollars"
                        />
                      </InputGroup>
                    )}
                  </Col>
                  <Col md={3}>
                    {isCeiling2Enabled && (
                      <InputGroup>
                        <Form.Control
                          placeholder="Bump%"
                          required
                          type="number"
                          step="0.01"
                          name="ceiling2BumpUpPercentage"
                        />
                        <InputGroup>
                          <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                        </InputGroup>
                      </InputGroup>
                    )}
                  </Col>
                </Row>

                {props.premiumLevel === 1 &&
                  props.domainName !== 'admin.emptyshelves.com' &&
                  props.domainName !== 'admin.bookteriors.com' && (
                    <>
                      {!props.triggerCatName.includes('_ca_') && (
                        <Row style={{ height: '50px' }}>
                          <Col style={{ paddingTop: '8px', paddingRight: '10' }} md={3}>
                            <Form.Group controlId="formIsCeilingKeepaCompare">
                              <Form.Check
                                onChange={handleCKeepaChange}
                                type="switch"
                                name="isCeilingKeepaEnabled"
                                id="is-ceiling-keepa"
                                label="Keepa Ceiling?"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            {isCeilingKeepaEnabled && (
                              <Form.Group controlId="formCeiling2Options">
                                <Form.Control style={{ fontSize: '13px' }} as="select" name="ceilingKeepaOptions">
                                  <option>...</option>
                                  <option>Median</option>
                                  <option>Percentile 80</option>
                                  <option>Percentile 20</option>
                                </Form.Control>
                              </Form.Group>
                            )}
                          </Col>
                          <Col md={3}>
                            {isCeilingKeepaEnabled && (
                              <InputGroup>
                                <InputGroup>
                                  <InputGroup.Text id="per-symbol">$</InputGroup.Text>
                                </InputGroup>
                                <Form.Control
                                  placeholder="Bump$"
                                  required
                                  type="number"
                                  step="any"
                                  name="ceilingKeepaBumpUpDollars"
                                />
                              </InputGroup>
                            )}
                          </Col>
                          <Col md={3}>
                            {isCeilingKeepaEnabled && (
                              <InputGroup>
                                <Form.Control
                                  placeholder="Bump%"
                                  required
                                  type="number"
                                  step="any"
                                  name="ceilingKeepaBumpUpPercentage"
                                />
                                <InputGroup>
                                  <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                                </InputGroup>
                              </InputGroup>
                            )}
                          </Col>
                        </Row>
                      )}

                      <Row style={{ height: '50px' }}>
                        <Col md={5} style={{ paddingTop: '8px' }}>
                          <Form.Group controlId="formIsMMCompare">
                            <Form.Check
                              onChange={(evt) => {
                                setIsMMEnabled(evt.target.checked);
                              }}
                              type="switch"
                              name="isMMEnabled"
                              id="is-mm"
                              label="Enable Marketplace Multiplier?"
                            />
                          </Form.Group>
                        </Col>

                        <Col md={7}>
                          {isMMEnabled && (
                            <InputGroup>
                              <Form.Control required type="number" step="0.01" name="mm_option" />
                              <InputGroup>
                                <InputGroup.Text id="per-symbol">X Target Profit</InputGroup.Text>
                              </InputGroup>
                            </InputGroup>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}

                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formIsBBCompare">
                      <Form.Check type="switch" name="isBBCompare" id="bb-compare" label="BB Compare?" />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Check type="switch" name="isAlwaysReject" id="always-reject" label="Always Reject?" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
