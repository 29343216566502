import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap';
import '../assets/style/login.css';
import { submitForgotPassword } from '../utils/helper';
import { loginPageBanner, loginPageEmail, logoWidth } from '../configs';
import { useNavigate } from 'react-router-dom';

type ForgotPasswordFormEvent = React.FormEvent<HTMLFormElement> & {
  target: HTMLFormElement & {
    elements: {
      user_email: {
        value: string;
      };
    };
  };
};

export default function ForgotPassword(): JSX.Element {
  const [logingin, setLogingin] = useState<boolean>(false);
  const [isInvalidLogin, setIsInvalidLogin] = useState<number>(-1);
  const [invalidLoginMessage, setInvalidLoginMessage] = useState<string>('');
  const [isTokenExpired, setIsTokenExpired] = useState(0);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    if (params.get('token-expiry') !== null) setIsTokenExpired(Number(params.get('token-expiry')));
  }, []);

  async function handleForgotPassword(event: ForgotPasswordFormEvent) {
    event.preventDefault();
    setIsInvalidLogin(-1);
    setInvalidLoginMessage('');
    const user_email = event.target.elements.user_email.value;

    setLogingin(true);
    const response = await submitForgotPassword(user_email);
    setLogingin(false);
    if (response.status === false) {
      setIsInvalidLogin(1);
      setInvalidLoginMessage(response.error);
    } else {
      setSuccessMessage('We have sent a password recover instructions to your email');
    }
  }

  return (
    <React.Fragment>
      <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
        <Col md={5} className="div-login-left">
          <div className="div-form-container">
            <div>
              <img
                alt="logo"
                className="logo"
                style={{ width: `${logoWidth}px` }}
                src={require(`../assets/images/nuprice-logo-dark.png`)}
              />
            </div>

            {isInvalidLogin === 1 && (
              <div style={{ marginTop: '20px' }}>
                <Alert variant="danger">
                  <span>{invalidLoginMessage}</span>
                </Alert>
              </div>
            )}
            <div>
              {isTokenExpired === 1 && (
                <Alert style={{ marginTop: '40px' }} key="auto-logout" variant="warning">
                  You have been logged out because you logged in from another device.
                </Alert>
              )}

              <div className="welcome-msg-login">
                Enter the email associated with your account and we'll send an email with instructions to reset your
                password
              </div>

              {successMessage !== '' && <div style={{ color: '#28A745', marginBottom: '20px' }}>{successMessage}</div>}

              <Form onSubmit={handleForgotPassword}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label style={{ marginBottom: '0px' }}>Email address</Form.Label>
                  <Form.Control
                    autoFocus
                    type="email"
                    name="user_email"
                    style={{ textAlign: 'center', width: '100%' }}
                    className="inputNoFormat"
                  />
                </Form.Group>

                {logingin ? (
                  <Spinner style={{ marginTop: '33px' }} animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  <Button
                    variant="info"
                    type="submit"
                    style={{
                      marginTop: '30px',
                      borderRadius: '0',
                      padding: '7px 25px 7px 25px',
                    }}
                  >
                    Send Instructions
                  </Button>
                )}
              </Form>

              <div className="support-msg" style={{ marginTop: '20px' }}>
                <Button
                  onClick={() => {
                    navigate('/');
                  }}
                  style={{ padding: '0' }}
                  variant="link"
                >
                  Click here to Login
                </Button>
              </div>

              <div className="support-msg">For any questions, please email us at {loginPageEmail}</div>
            </div>
          </div>
        </Col>
        <Col
          md={7}
          style={{
            background: `url(${require('../assets/images/' + loginPageBanner)}) no-repeat center center`,
            backgroundSize: 'cover',
            backgroundPosition: 'left 0px top 0px',
          }}
        ></Col>
      </Row>
    </React.Fragment>
  );
}
