import React from 'react';
import { Form, Modal, Button, FormControl } from 'react-bootstrap';
import { useRef, useEffect } from 'react';

interface NewTriggerBatchProps {
  showAddModal: boolean;
  handleClose: () => void;
  onFormSubmitAddTB: (e: any) => void;
}
export default function NewTriggerBatch(props: NewTriggerBatchProps) {
  const batchNameInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (batchNameInput.current) batchNameInput.current.focus();
  }, []);

  return (
    <Modal show={props.showAddModal} onHide={props.handleClose} dialogClassName="editModal">
      <Form onSubmit={props.onFormSubmitAddTB}>
        <Modal.Header closeButton>
          <FormControl
            required
            ref={batchNameInput}
            type="text"
            name="tbName"
            className="input-text-trigger-name"
            placeholder="Enter Trigger Set Name Here"
            autoFocus
          />
        </Modal.Header>

        <Modal.Footer style={{ border: 'none' }}>
          <Button variant="primary" type="submit">
            Add Now
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
