import React from 'react';
import { Form, Modal, Button, Spinner, Table, Row, Col } from 'react-bootstrap';
import animationData from '../assets/animations/68339-labs.json';
import Lottie from 'lottie-react';
import { UpdateUserMWSCredentialsEvent } from '../pages/Settings';

interface ModalGettingStartedProps {
  showGettingStartedModal: boolean;
  handleClose: () => void;
  isFetchingInventory: boolean;
  updateUserMWSCredentials: (e: UpdateUserMWSCredentialsEvent) => Promise<void>;
  mwsError: any;
  isUpdatingMWSCredentials: boolean;
}

export default function ModalGettingStarted(props: ModalGettingStartedProps) {
  function handleOpen() {}
  return (
    <>
      <Modal
        style={{ zIndex: 999999 }}
        backdrop="static"
        keyboard={false}
        onEntered={handleOpen}
        show={props.showGettingStartedModal}
        onHide={props.handleClose}
        dialogClassName="editModal"
      >
        <Modal.Header>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <h3>Seller account authorization required</h3>
          </div>
        </Modal.Header>
        <Modal.Body>
          {props.isFetchingInventory === true ? (
            <div style={{ textAlign: 'center' }}>
              <p>
                We're pulling in your inventory. This might take a few minutes. Please feel free to close this tab and
                come back later.
              </p>
              <div style={{ marginTop: '20px' }}>
                <Lottie
                  animationData={animationData}
                  loop={true}
                  autoplay={true}
                  rendererSettings={{
                    preserveAspectRatio: 'xMidYMid slice',
                  }}
                />
              </div>
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <div style={{ textAlign: 'center' }}>
                To get started, we'll need you to connect your Amazon Account. Click below button to initiate
                connection.
              </div>

              <div style={{ marginTop: '20px' }}>
                <Button
                  variant="success"
                  onClick={() => {
                    window.open(
                      'https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sellerapps.app.b675694d-3f7b-4ec6-bb14-2f135560bda7&state=REPRICEIQ&version=beta&redirect_uri=https://app.repriceiq.co/settings',
                      '_self',
                      'noopener,noreferrer'
                    );
                  }}
                >
                  Click here to Authorize
                </Button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
