import Cookies from 'universal-cookie';
import { apiBaseUrlLocal } from '../configs';

type CallBack = () => void;

export interface UserData {
  id: string;
  email: string;
  token: string;
  stripe_customer_id: any;
  admin_id: string;
  stripe_payment_id: string;
  card_last4?: string;
  card_brand?: string;
  premium_level: number;
  reprice_above_below?: string;
  mws_seller_id?: string;
  mws_auth_token?: string;
  sp_access_token?: string;
  sp_refresh_token?: string;
  is_active: number;
  mws_error: number;
  floor_profit: number;
  version: any;
  trigger_batch_id: string;
}

interface LoginResponse {
  data: unknown;
}

class Auth {
  cookies: Cookies;
  constructor() {
    this.cookies = new Cookies();
  }

  login(response: LoginResponse, cb: CallBack) {
    this.cookies.set('admin_data', response.data, { path: '/' });
    cb();
  }

  logout(cb: CallBack) {
    this.cookies.remove('admin_data');
    cb();
  }

  getAdminData(): UserData {
    return this.cookies.get('admin_data');
  }
  setAdminData(userData: UserData) {
    this.cookies.set('admin_data', userData, { path: '/' });
  }

  async isAuthenticated() {
    const adminData = this.cookies.get('admin_data');
    if (undefined !== adminData && 'token' in adminData) {
      try {
        const response = await fetch(`${apiBaseUrlLocal}user/validate-token?id=${adminData.token}`);
        const responseJson = await response.json();
        if (response.status === 200) {
          this.setAdminData(responseJson.data);
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  }
}

export default new Auth();
