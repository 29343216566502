import React from 'react';
import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { versionId } from '../configs';

interface NewVersionProps {
  showNewVersionNotification: boolean;
  handleClose: () => void;
  updateNotes: string;
}

export default function NewVersion(props: NewVersionProps) {
  const [updateNotes, setUpdateNotes] = useState([]);

  useEffect(() => {
    setUpdateNotes(props.updateNotes ? JSON.parse(props.updateNotes) : []);
  }, [props.updateNotes]);

  function handleOpen() {}
  return (
    <>
      <Modal
        onEntered={handleOpen}
        show={props.showNewVersionNotification}
        onHide={props.handleClose}
        dialogClassName="editModal"
      >
        <Modal.Header closeButton>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <h3>Welcome to V.{versionId / 10}</h3>
          </div>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'left' }}>
          <div>
            <h5>New Features</h5>

            {updateNotes.map((updateNote, index) => {
              return <div key={`update-note-${index}`}># {updateNote}</div>;
            })}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
