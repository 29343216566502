import React from 'react';
import { useRef, useEffect } from 'react';
import { Form, Modal, Button, FormControl, Spinner } from 'react-bootstrap';

interface ImportTriggerBatchProps {
  showImportModal: boolean;
  handleClose: () => void;
  onFormSubmitImportTB: (e: any) => void;
  importingTB: boolean;
}

export function ImportTriggerBatch(props: ImportTriggerBatchProps): JSX.Element {
  const batchNameInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (batchNameInput.current) batchNameInput.current.focus();
  }, []);

  return (
    <Modal show={props.showImportModal} onHide={props.handleClose} dialogClassName="editModal">
      <Form onSubmit={props.onFormSubmitImportTB}>
        <Modal.Header closeButton>
          <FormControl
            style={{ padding: '0px' }}
            autoCorrect="off"
            spellCheck={false}
            required
            ref={batchNameInput}
            type="text"
            name="hashCode"
            className="input-text-trigger-name"
            placeholder="Enter Hash Code here"
            autoFocus
          />
        </Modal.Header>

        <Modal.Body>
          <p>Enter the 32 character long hash code above to import pre-existing trigger set into your account.</p>
        </Modal.Body>

        <Modal.Footer style={{ border: 'none' }}>
          <Button variant="primary" type="submit" style={{ width: '150px' }}>
            {props.importingTB ? (
              <Spinner style={{ width: '18px', height: '18px' }} animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <span> Import Now</span>
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
