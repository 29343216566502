import { apiBaseUrlLocal } from '../configs';
import axios from 'axios';
import moment from 'moment';

export const submitLogin = async (username: string, password: string, captchaToken: string) => {
  try {
    const response = await fetch(apiBaseUrlLocal + 'user/login', {
      method: 'POST',
      body: new URLSearchParams({
        username: username,
        password: password,
        captcha_token: captchaToken,
      }),
    });
    const data = await response.json();
    return data;
  } catch {
    return {
      status: false,
      error: 'Error connecting to API server',
    };
  }
};

export const submitForgotPassword = async (user_email: string) => {
  const response = await fetch(apiBaseUrlLocal + 'user/forgot-password', {
    method: 'POST',
    body: new URLSearchParams({
      user_email: user_email,
    }),
  });
  const data = await response.json();
  return data;
};

export const submitResetPassword = async (app_token: string, new_password: string) => {
  const response = await fetch(apiBaseUrlLocal + 'user/reset-password', {
    method: 'POST',
    body: new URLSearchParams({
      app_token: app_token,
      new_password: new_password,
    }),
  });
  const data = await response.json();
  return data;
};

export const validateInviteToken = async (token: string) => {
  const response = await fetch(apiBaseUrlLocal + `user/validate-invite-token?token=${token}`);
  const data = await response.json();
  return data;
};

export const submitSignup = async (fullName: string, user_email: string, password: string) => {
  const response = await fetch(apiBaseUrlLocal + 'user/signup-free', {
    method: 'POST',
    body: new URLSearchParams({
      user_email: user_email,
      full_name: fullName,
      password: password,
    }),
  });
  const data = await response.json();
  return data;
};

export const getUSFormattedDate = (dateString: string) => {
  var date = new Date(dateString);
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '-' + day + '-' + year;
};

export const getAgeSince = (listedDate: Date | string | number) => {
  return moment().diff(moment(listedDate), 'days');
};

export const nFormatter = (num: number, digits: number) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

export const timeSince = (date: Date) => {
  return moment.duration(moment().diff(date)).humanize();
};

export const getFormattedDate = (dt: Date) => {
  var dd = dt.getDate();
  var mm = dt.getMonth() + 1;

  var yyyy = dt.getFullYear();
  if (dd < 10) {
    dd = Number('0' + dd);
  }
  if (mm < 10) {
    mm = Number('0' + mm);
  }

  return yyyy + '-' + mm + '-' + dd;
};

export const mwsConditionIds = {
  '-1': { main: 'Used', sub: '' },
  1: { main: 'Used', sub: 'Like New' },
  2: { main: 'Used', sub: 'Very Good' },
  3: { main: 'Used', sub: 'Good' },
  4: { main: 'Used', sub: 'Acceptable' },
  5: { main: 'Collectible', sub: 'Like New' },
  6: { main: 'Collectible', sub: 'Very Good' },
  7: { main: 'Collectible', sub: 'Good' },
  8: { main: 'Used', sub: 'Like New' },
  9: { main: 'Used', sub: 'Not Used' },
  10: { main: 'Refurbished', sub: '' },
  11: { main: 'New', sub: '' },
};

export const callApi = (
  endpoint: string,
  method: 'POST' | 'GET' | 'DELETE' | 'PUT',
  options: any,
  callback: (data: any) => void
) => {
  let apiObject: {
    method: 'POST' | 'GET' | 'DELETE' | 'PUT';
    url: string;
    data?: any;
  } = {
    method: method,
    url: apiBaseUrlLocal + endpoint,
  };

  if (options) apiObject['data'] = options;

  axios(apiObject)
    .then((response) => {
      if (response.status === 200) callback(response.data);
      else {
        console.error(`Incorrect response from API`, response.data);
        callback(false);
      }
    })
    .catch((error) => {
      const errorStatus = error?.response?.status || -1;
      const errorMsg = error?.response?.data?.error ?? 'Unknown error';

      console.log({ status: false, detail: errorMsg, httpCode: errorStatus });

      callback(false);
    });
};

// export const callApi = (endpoint: string, method: string, options: any, callback: (data: any) => void) => {
//   fetch(apiBaseUrlLocal + endpoint, {
//     method,
//     body: new URLSearchParams(options),
//   })
//     .then((resp) => resp.json())
//     .then((data) => {
//       if (data.status) callback(data.data);
//     })
//     .catch((error) => {
//       callback(false);
//     });
// };

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
