import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Elements } from '@stripe/react-stripe-js';
import Lottie from 'lottie-react';

import animationData from '../assets/animations/64787-success.json';
import CardForm from './CardForm';
import auth, { UserData } from '../utils/auth';

const stripePromise = loadStripe(
  'pk_live_51JxYDNFhL1we7j7vKW2SZ3fS1oiKNBCCufHMljP24fMj8yR0GiK0n5BILed5Bw7ObHT9dfd5YiS5UjWykISkeF5u008McbvFx7'
);

const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

interface ModalStripeSetupProps {
  showStripeSetupModal: boolean;
  handleClose: () => void;
}

export function ModalStripeSetup(props: ModalStripeSetupProps) {
  const [userData, setUserData] = useState<UserData>(auth.getAdminData());
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [currentPlan, setCurrentPlan] = useState('yearly');
  const [stripeError, setStripeError] = useState('');
  const [currentPage, setCurrentPage] = useState('description');

  function updateAdminData() {}

  function handleOpen() {}

  return (
    <>
      <Modal
        style={{ zIndex: 99999 }}
        backdrop="static"
        keyboard={false}
        onEntered={handleOpen}
        show={props.showStripeSetupModal}
        onHide={props.handleClose}
        dialogClassName="editModal"
      >
        <Modal.Header>
          <div style={{ width: '100%', textAlign: 'center' }}>
            {currentPage === 'payment-success' ? (
              <div>
                <h3>All set!</h3>
              </div>
            ) : (
              <div>
                <h3>Add a payment method</h3>
              </div>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: 'center' }}>
            {currentPage === 'description' ? (
              <div>
                <div
                  style={{
                    color: '#4CA7F8',
                    fontSize: '32px',
                    fontWeight: 'bold',
                  }}
                >
                  NUPRICE 2.0 IS LIVE!
                </div>
                {/* <div style={{ marginTop: "8px", fontWeight: "bold", fontSize: "20px" }}>
                    You qualify for a limited time launch discount!
                  </div> */}

                <div style={{ marginTop: '20px' }}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div
                      className="plan-yearly"
                      onClick={() => {
                        setCurrentPage('card');
                        setCurrentPlan('yearly');
                        setStripeError('');
                      }}
                    >
                      <div
                        className="plan-desc"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingLeft: '60px',
                          paddingRight: '60px',
                        }}
                      >
                        <div className="plan-sale-price">$129/year</div>
                        {/* <div className="plan-price">$129/year</div> */}
                      </div>
                      <div className="plan-cta">SELECT PLAN</div>
                    </div>

                    <div
                      className="plan-monthly"
                      onClick={() => {
                        setCurrentPage('card');
                        setCurrentPlan('monthly');
                        setStripeError('');
                      }}
                    >
                      <div
                        className="plan-desc"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingLeft: '10px',
                          paddingRight: '10px',
                        }}
                      >
                        <div className="plan-sale-price">$14.99/month</div>
                      </div>
                      <div className="plan-cta">SELECT PLAN</div>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: '20px' }}>
                  <div className="payment-msg">
                    We are officially launching NuPrice 2.0. This means as of today, we are closing out all past
                    subscriptions and starting new subscriptions on the web app. We are giving you a 2-week trial of the
                    latest software in addition, so payments will not start right away. If you are an annual customer
                    and have time left on your current subscription, please reach out to support within the trial period
                    with your license key, and we will add a credit to your new account. -{' '}
                    <span style={{ fontWeight: 'bold', textDecoration: 'italics' }}>Caleb & Matthew</span>
                  </div>
                </div>
              </div>
            ) : currentPage === 'payment-success' ? (
              <div>
                <Lottie {...defaultLottieOptions} height={400} width={400} />
              </div>
            ) : (
              <div>
                <div>
                  <div style={{ fontSize: '18px', fontWeight: 'bold' }}>
                    Plan Selected: {currentPlan === 'yearly' ? 'Yearly - $129/yr' : 'Monthly - $14.99/mo'}
                  </div>
                  <div>
                    <Button
                      variant="link"
                      onClick={() => {
                        setCurrentPage('description');
                      }}
                    >
                      Click to change
                    </Button>
                  </div>
                </div>
                <div style={{ padding: '10px 0 10px 0', color: 'red' }}>{stripeError}</div>
                <Elements stripe={stripePromise}>
                  <CardForm
                    paymentSuccess={() => {
                      setCurrentPage('payment-success');
                      setTimeout(() => {
                        props.handleClose();
                      }, 3000);
                    }}
                    currentPlan={currentPlan}
                    updateCardErrorMessage={(error?: string) => setStripeError(error || '')}
                    isAddingCard={isAddingCard}
                    admin_data={userData}
                    updateAdminData={updateAdminData}
                  />
                </Elements>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
