import React from 'react';
import { useEffect, useState } from 'react';
import { Row, Col, Container, Table, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import animationData from '../assets/animations/9329-loading.json';
import Lottie from 'lottie-react';
import auth from '../utils/auth';
import { callApi, timeSince } from '../utils/helper';
import { TopMenu } from '../components/TopMenu';
import { Feed, ModalRepricedItems } from '../components/RepriceItems';

const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function RepriceHistory() {
  const userData = auth.getAdminData();
  const navigate = useNavigate();
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [feedsHistory, setFeedsHistory] = useState([]);
  const [showRepricedItemsModel, setShowRepricedItemsModel] = useState(false);
  const [selectedFeed, setSelectedFeed] = useState<Feed>();

  useEffect(() => {
    setIsLoadingHistory(true);
    callApi(
      `inventory/history/feeds/1`,
      'POST',
      {
        user_id: userData.id,
        app_token: userData.token,
      },
      (response) => {
        setIsLoadingHistory(false);
        if ('status' in response && response.status === true) setFeedsHistory(response.data);
      }
    );
  }, []);

  return (
    <>
      <TopMenu refreshInventory={() => {}} headerTitle="Reprice History" activeKey="/" />

      <ModalRepricedItems
        userData={userData}
        handleClose={() => setShowRepricedItemsModel(false)}
        showRepricedItemsModal={showRepricedItemsModel}
        selectedFeed={selectedFeed}
      />

      <Container fluid={true}>
        <Row style={{ marginTop: '20px' }}>
          <Col md={12}>
            <div style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    fontSize: '22px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigate('/settings');
                  }}
                >
                  Reprice History
                </div>
              </div>
              <div style={{ fontSize: '13px' }}>Click a row to see associated items</div>
            </div>

            <div style={{ marginTop: '20px' }}>
              {isLoadingHistory === true ? (
                <div>
                  <Lottie {...defaultLottieOptions} height={400} width={400} />
                </div>
              ) : (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Amazon ID</th>
                      <th style={{ textAlign: 'center' }}>Status</th>
                      <th style={{ textAlign: 'center' }}>Submitted</th>
                      <th style={{ textAlign: 'center' }}>Last Checked</th>
                      <th style={{ textAlign: 'center' }}>Total Items</th>
                    </tr>
                  </thead>
                  <tbody>
                    {feedsHistory && isLoadingHistory === false && feedsHistory.length === 0 ? (
                      <tr>
                        <td style={{ textAlign: 'center' }} colSpan={6}>
                          No Records found.
                        </td>
                      </tr>
                    ) : (
                      feedsHistory.map((feed: Feed) => (
                        <tr
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setSelectedFeed(feed);
                            setShowRepricedItemsModel(true);
                          }}
                        >
                          <td>{feed.submission_id}</td>
                          <td style={{ textAlign: 'center' }}>
                            {feed.status === 'SUBMITTED' ? (
                              <div>
                                <span style={{ marginLeft: '20px' }}>
                                  <img
                                    style={{ width: '20px' }}
                                    alt="SCOUTIQ"
                                    src={require('../assets/animations/loading-gear.gif')}
                                  />
                                </span>
                                <span
                                  style={{
                                    marginLeft: '2px',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    color: '#316CF4',
                                  }}
                                >
                                  Processing
                                </span>
                              </div>
                            ) : feed.status === 'ERRORED' ? (
                              <Badge
                                bg="danger"
                                style={{
                                  background: '#CB444A',
                                  color: 'white',
                                  padding: '3px',
                                }}
                              >
                                ERRORED
                              </Badge>
                            ) : (
                              <Badge
                                bg="success"
                                style={{
                                  background: '#408558',
                                  color: 'white',
                                  padding: '3px',
                                }}
                              >
                                FINISHED
                              </Badge>
                            )}
                          </td>
                          <td style={{ textAlign: 'center' }}>{timeSince(new Date(feed.submitted_on))} ago</td>
                          <td style={{ textAlign: 'center' }}>{timeSince(new Date(feed.last_checked))} ago</td>
                          <td style={{ textAlign: 'center' }}>{feed.total_items}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
