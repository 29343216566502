import styled from 'styled-components';
import { styled as styledTabTab } from '@react-tabtab-next/tabtab';

let { ActionButton, Tab, Panel } = styledTabTab;

Tab = styled(Tab)`
  background-color: #e9ecef;
  transition: color 0.28s ease;
  ${(props) =>
    props.active && !props.vertical ? ` border-bottom: 2px solid #232327;background-color: #007BFF;color: #fff;` : null}
  &:hover {
    color: white;
    background-color: #007bff;
    border-bottom: 2px solid #232327;
  }
`;

ActionButton = styled(ActionButton)`
  background-color: blue;
  display: hidden !important;
  border-radius: 0;
  &:hover {
    background-color: #eee;
  }
`;

Panel = styled(Panel)`
  padding: 15px 0 0 0;
  background-color: white;
`;

export { Tab, ActionButton, Panel };
