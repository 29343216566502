import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TopMenu } from '../components/TopMenu';
import auth from '../utils/auth';
import { Row, Col, Container, Table, Button, Spinner, Form, Tabs, Tab } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Billing from './Settings/Billing';
import { callApi } from '../utils/helper';

interface TriggerBatch {
  id: string;
  status: number;
  name: string;
}

export type UpdateUserMWSCredentialsEvent = React.FormEvent<HTMLFormElement> & {
  target: HTMLFormElement & {
    elements: {
      mwsAuthToken: {
        value: string;
      };
      mwsSellerId: {
        value: string;
      };
    };
  };
};

type UpdateUserSettingsEvent = React.FormEvent<HTMLFormElement> & {
  target: HTMLFormElement & {
    elements: {
      priceAboveBelow: {
        value: string;
      };
      userEmail: {
        value: string;
      };
      currentBatch: {
        value: string;
      };
    };
  };
};

export default function Settings() {
  const navigate = useNavigate();
  const userData = auth.getAdminData();
  const [triggerBatches, setTriggerBatches] = useState<TriggerBatch[]>([]);
  const [isUpdatingSPAPICode, setIsUpdatingSPAPICode] = useState(false);

  const [isLoadingBatches, setIsLoadingBatches] = useState(false);
  const [isLoadingDefaults, setIsLoadingDefaults] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [activeTab, setActiveTab] = useState('basics');
  const [updatingSettings, setUpdatingSettings] = useState(false);
  const [changingActive, setChangingActive] = useState(false);
  const [mwsError, setMwsError] = useState('');

  async function getTriggerBatches() {
    setIsLoadingBatches(true);
    callApi(
      `triggers/batches/list`,
      'POST',
      {
        user_id: userData.id,
        app_token: userData.token,
      },
      (response) => {
        setIsLoadingBatches(false);
        if ('status' in response && response.status === true) setTriggerBatches(response.data);
      }
    );
  }

  function updateCurrentActiveTriggerSet(triggerBatchId: string) {
    setChangingActive(true);
    callApi(
      `triggers/mark-active`,
      'POST',
      {
        user_id: userData.id,
        app_token: userData.token,
        batch_id: triggerBatchId,
      },
      (response) => {
        setChangingActive(false);
        if ('status' in response && response.status === true) setTriggerBatches(response.data);
      }
    );
  }

  function updateUserSettings(event: UpdateUserSettingsEvent) {
    event.preventDefault();

    const newSettings = {
      activeTriggerBatchId: event.target.elements.currentBatch.value,
      userEmail: event.target.elements.userEmail.value,
      priceAboveBelow: event.target.elements.priceAboveBelow.value,
    };

    setUpdatingSettings(true);

    callApi(
      `settings/`,
      'PUT',
      {
        user_id: userData.id,
        app_token: userData.token,
        new_settings: JSON.stringify(newSettings),
      },
      (response) => {
        setUpdatingSettings(false);
      }
    );
  }

  const initiate = async () => {
    console.log(`Reading parameters ...`);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    const spApiOAuthCode = params.get('spapi_oauth_code');
    const sellerId = params.get('selling_partner_id');

    if (spApiOAuthCode) {
      setActiveTab('mws');
      setIsUpdatingSPAPICode(true);
      console.log(`SP API oAuth Code: `, spApiOAuthCode);

      callApi(
        `settings/sp-auth/${spApiOAuthCode}`,
        'PUT',
        {
          user_id: userData.id,
          app_token: userData.token,
          seller_id: sellerId,
        },
        (response) => {
          if (response && response.status) {
            window.location.reload();
          }
        }
      );
    }

    setIsLoadingDefaults(true);
    await getTriggerBatches();
    setIsLoadingDefaults(false);
  };

  useEffect(() => {
    initiate();
  }, []);

  return (
    <>
      <TopMenu activeKey="/" refreshInventory={() => {}} />
      <Container fluid={true}>
        <Row style={{ marginTop: '20px' }}>
          <Col md={12}>
            <div style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    fontSize: '22px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigate('/settings');
                  }}
                >
                  NuPrice Settings
                </div>
              </div>
            </div>

            <Tabs
              mountOnEnter
              unmountOnExit
              defaultActiveKey="basics"
              transition={false}
              id="noanim-tab-example"
              className="mb-3"
              style={{ marginTop: '30px' }}
            >
              <Tab eventKey="basics" title="Basics">
                <Form onSubmit={updateUserSettings}>
                  <Row>
                    <Col md={6}>
                      <Table bordered>
                        <tbody>
                          <tr>
                            <td style={{ fontSize: '15px', fontWeight: 'bold' }}>Active Trigger Batch</td>
                            <td>
                              <Form.Control
                                style={{ minWidth: '200px' }}
                                as="select"
                                disabled={changingActive}
                                name="currentBatch"
                                onChange={(e) => {
                                  updateCurrentActiveTriggerSet(e.target.value);
                                }}
                              >
                                <option value="-1">...</option>
                                {triggerBatches.map((tb) => (
                                  <option value={tb.id} selected={tb.status === 1}>
                                    {tb.name}
                                  </option>
                                ))}
                              </Form.Control>
                            </td>
                            <td>
                              <Button
                                style={{ fontWeight: 'bold' }}
                                variant="link"
                                onClick={() => {
                                  navigate('/settings/trigger-batches');
                                }}
                              >
                                Manage trigger batches
                              </Button>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ fontSize: '15px', fontWeight: 'bold' }}>Email Address</td>
                            <td colSpan={2}>
                              <Form.Control defaultValue={userData.email} type="email" name="userEmail" />
                            </td>
                          </tr>

                          <tr>
                            <td style={{ fontSize: '15px', fontWeight: 'bold' }}>
                              Always reprice above/below target or buybox
                            </td>
                            <td colSpan={2}>
                              <Form.Control
                                defaultValue={userData.reprice_above_below || ''}
                                type="number"
                                step="0.01"
                                name="priceAboveBelow"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col md={6}></Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <Button
                        variant={isLoadingDefaults === true ? 'secondary' : 'primary'}
                        type="submit"
                        disabled={isLoadingDefaults}
                      >
                        {updatingSettings === true ? (
                          <Spinner animation="border" role="status"></Spinner>
                        ) : (
                          <span>Save Changes</span>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Tab>

              <Tab eventKey="mws" title="MWS">
                <Row>
                  <Col md={6}>
                    <Table bordered>
                      <tbody>
                        <tr>
                          <td style={{ fontWeight: 'bold' }}>Amazon Integration Status:</td>

                          <td>
                            {userData.sp_refresh_token ? (
                              <div
                                style={{
                                  backgroundColor: 'green',
                                  color: 'white',
                                  padding: '10px',
                                  borderRadius: '5px',
                                  textAlign: 'center',
                                }}
                              >
                                Already connected
                              </div>
                            ) : isUpdatingSPAPICode ? (
                              `Processing. please wait ...`
                            ) : (
                              <Button
                                variant="warning"
                                size="sm"
                                onClick={() => {
                                  window.open(
                                    'https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sellerapps.app.b675694d-3f7b-4ec6-bb14-2f135560bda7&state=REPRICEIQ&version=beta&redirect_uri=https://app.repriceiq.co/settings',
                                    '_blank',
                                    'noopener,noreferrer'
                                  );
                                }}
                              >
                                Click here to connect
                              </Button>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col md={6}></Col>
                </Row>
              </Tab>
              <Tab eventKey="billing" title="Billing">
                <Billing userData={userData} />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
}
