import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from './components/ProtectedRoute';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';
import { EditTriggerBatch } from './pages/EditTriggerBatch';
import Home from './pages/Home';
import RepriceHistory from './pages/RepriceHistory';
import ResetPassword from './pages/ResetPassword';
import Route404 from './pages/Route404';
import Settings from './pages/Settings';
import SetupPayment from './pages/SetupPayment';
import TriggerBatches from './pages/Settings/TriggerBatches';
import './assets/style/theme_minimal.css';
import 'react-tabulator/css/bootstrap/tabulator_bootstrap.min.css'; // use Theme(s)

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
        <Route path="/settings" element={<ProtectedRoute element={<Settings />} />} />
        <Route path="/history" element={<ProtectedRoute element={<RepriceHistory />} />} />
        <Route
          path="/settings/trigger-batches/edit-trigger-batch/:triggerBatchId"
          element={<ProtectedRoute element={<EditTriggerBatch />} />}
        />
        <Route path="/settings/trigger-batches" element={<ProtectedRoute element={<TriggerBatches />} />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/setup-payment" element={<SetupPayment />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<Route404 />} />
      </Routes>
    </Router>
  );
}

export default App;
