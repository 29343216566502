import React from 'react';
import { useEffect, useState } from 'react';
import auth from '../utils/auth';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { TopMenu } from '../components/TopMenu';
import animationData from '../assets/animations/9329-loading.json';
import Lottie from 'lottie-react';
import { AnyMxRecord } from 'dns';
import AmazonTriggers from '../components/AmazonTriggers';
import { Tabs, Panel, Tab, TabList, PanelList } from '@react-tabtab-next/tabtab';
import { getTBConfiguration } from '../utils/helperTriggerBatches';

interface EditTriggerBatchProps {}

const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export function EditTriggerBatch(props: EditTriggerBatchProps) {
  const userData = auth.getAdminData();
  const { triggerBatchId } = useParams();
  const navigate = useNavigate();
  const [batchConfiguration, setBatchConfiguration] = useState<Record<string, string>>({});
  const [triggerBatch, setTriggerBatch] = useState({});
  const [triggerBatchNew, settriggerBatchNew] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);

  function updateTBConfig(level: number, new_amz_config: AnyMxRecord) {
    let batchConfigurationClone = batchConfiguration;
    batchConfigurationClone[level] = JSON.stringify(new_amz_config);

    setBatchConfiguration(batchConfigurationClone);
  }

  function handleTabSequenceChange() {}

  async function initiate() {
    setBatchConfiguration({});
    const tbatchConfig = await getTBConfiguration(userData, Number(triggerBatchId));

    if (tbatchConfig !== false) setBatchConfiguration(tbatchConfig.data);
  }

  useEffect(() => {
    initiate();
  }, []);

  const tabs = [
    {
      title: `Used`,
      targetDiff: 0,
      enabled: true,
      content: (
        <AmazonTriggers
          batchConfiguration={batchConfiguration}
          triggersType={'mf_fba_conf_used'}
          updateTBConfig={updateTBConfig}
          triggerBatchId={Number(triggerBatchId)}
        />
      ),
    },
    {
      title: `New`,
      targetDiff: 0,
      enabled: true,
      content: (
        <AmazonTriggers
          batchConfiguration={batchConfiguration}
          triggersType={'mf_fba_conf_new'}
          updateTBConfig={updateTBConfig}
          triggerBatchId={Number(triggerBatchId)}
        />
      ),
    },
  ];

  const tabsTemplate: JSX.Element[] = [];
  const panelTemplate: JSX.Element[] = [];
  tabs.forEach((tab, index) => {
    tabsTemplate.push(<Tab key={index}>{tab.title}</Tab>);
    panelTemplate.push(<Panel key={index}>{tab.content}</Panel>);
  });

  return (
    <>
      <TopMenu refreshInventory={() => {}} activeKey="/" />
      <Container fluid={true} className="page-dashboard">
        <Row style={{ marginTop: '20px' }}>
          <Col md={12}>
            <div style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px' }}>
              <h4>
                <i
                  onClick={() => {
                    navigate(-1);
                  }}
                  style={{
                    marginRight: '10px',
                    color: '#2E3338',
                    cursor: 'pointer',
                  }}
                  className="fa fa-arrow-circle-left "
                />{' '}
                Edit Trigger Batch {triggerBatchId}
              </h4>
            </div>

            {batchConfiguration === null ? (
              <div style={{ marginTop: '20px' }}>
                <Lottie {...defaultLottieOptions} height={400} width={400} />
              </div>
            ) : (
              <div style={{ marginTop: '20px' }}>
                <div style={{ marginTop: '20px' }}>
                  <Tabs
                    showModalButton={false}
                    activeIndex={activeIndex}
                    onTabChange={(index: number) => setActiveIndex(index)}
                    onTabSequenceChange={handleTabSequenceChange}
                  >
                    <TabList>{tabsTemplate}</TabList>
                    <PanelList>{panelTemplate}</PanelList>
                  </Tabs>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
