import React from 'react';
import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import auth from '../utils/auth';
import { apiBaseUrlLocal, versionId } from '../configs';
import {
  callApi,
  getAgeSince,
  getFormattedDate,
  mwsConditionIds,
  nFormatter,
  numberWithCommas,
  timeSince,
} from '../utils/helper';
import { ModalStripeSetup } from '../components/StripeSetup';
import { ConfirmReprice } from '../components/ConfirmReprice';
import ModalGettingStarted from '../components/GettingStarted';
import {
  Container,
  Row,
  Col,
  Spinner,
  FormControl,
  InputGroup,
  Button,
  ProgressBar,
  Table,
  FormCheck,
  Badge,
  OverlayTrigger,
  Tooltip,
  Form,
} from 'react-bootstrap';
import NewVersion from '../components/NewVersion';
import { TopMenu } from '../components/TopMenu';
import { UpdateUserMWSCredentialsEvent } from './Settings';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import { DiscontinueBanner } from '../components/DiscontinueBanner';
import moment from 'moment';

type NewPriceFormEvent = React.FormEvent<HTMLFormElement> & {
  target: HTMLFormElement & {
    elements: {
      newPrice: {
        value: string;
      };
    };
  };
};

type UpdateFloorProfitEvent = React.FormEvent<HTMLFormElement> & {
  target: HTMLFormElement & {
    elements: {
      floorProfit: {
        value: string;
      };
    };
  };
};

interface InventoryItem {
  id: string;
  name: string;
  target: any;
  price: number;
  escore: number;
  sales_rank: number;
  category: string;
  buybox: any;
  item_condition: keyof typeof mwsConditionIds;
  buybox_new: any;
  buybox_used: any;
  amazon_price: number;
  mf_offers: any;
  fba_offers: any;
  mf_offers_conditions: any;
  fba_offers_conditions: any;
  is_fba: number;
  asin: string | number;
  sku: string | number;
  new_price: number;
  repriced_on: number | string;
  open_date: number | string | Date;
}

interface TriggerBatch {
  id: string;
  status: number;
  name: string;
}

interface RepriceObj {
  id: string;
  asin: string | number;
  sku: string | number;
  currentPrice: number;
  newPrice: number;
  profit: number;
  originalNewPrice: number;
  totalFee?: number;
}

export default function Home() {
  const [userData] = useState(auth.getAdminData());
  const [clearRefreshInterval, setClearRefreshInterval] = useState(false);
  const [floorProfit, setFloorProfit] = useState(1);
  const [checkedItems, setCheckedItems] = useState<InventoryItem[]>([]);
  const [priceLoadProgress, setPriceLoadProgress] = useState(0);
  const [showGettingStartedModal, setShowGettingStartedModal] = useState(false);
  const [currentLivePriceExeTimestamp, setCurrentLivePriceExeTimestamp] = useState<number | null>(null);
  const [isUpdatingFloorProfit, setIsUpdatingFloorProfit] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [infoBoxesCount, setInfoBoxesCount] = useState({
    active: -1,
    inactive: -1,
    stranded: -1,
    repriced_today: -1,
    repriced_week: -1,
    repriced_month: -1,
  });
  const [triggersList, setTriggersList] = useState([
    {
      value: 1,
      label: 'Trigger 1',
    },
  ]);
  const [currentTriggerId, setCurrentTriggerId] = useState(1);
  const [fetchedLivePrices, setFetchedLivePrices] = useState<boolean>(true);

  // Filters
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [fulfillmentChannel, setFulfillmentChannel] = useState('');
  const [listingView, setListingView] = useState('active');
  const [allChecked, setAllChecked] = useState(false);
  const [showNewVersionNotification, setShowNewVersionNotification] = useState(false);
  const [updateNotes, setUpdateNotes] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  // Feeds
  const [pendingFeeds, setPendingFeeds] = useState(0);

  // Trigger Batches:
  const [changingActive, setChangingActive] = useState(false);
  const [isLoadingBatches, setIsLoadingBatches] = useState(false);
  const [triggerBatches, setTriggerBatches] = useState<TriggerBatch[]>([]);

  // Inventory
  const [totalItemsViewCount, setTotalItemsViewCount] = useState(10);
  const [isLoadingInventoryStats, setIsLoadingInventoryStats] = useState(false);
  const [isLoadingInventory, setIsLoadingInventory] = useState(false);
  const [itemsInventory, setItemsInventory] = useState<InventoryItem[]>([]);
  const [displayInventoryType, setDisplayInventoryType] = useState('active');
  const [isRefreshingInventory, setIsRefreshingInventory] = useState(false);
  const [changingPerPageCount, setChangingPerPageCount] = useState(50);
  const [onlyShowCurrentNotTarget, setOnlyShowCurrentNotTarget] = useState(false);
  const [orderBy, setOrderBy] = useState('recently-added');
  const [currentInventoryRefreshStatus, setCurrentInventoryRefreshStatus] = useState('done');
  const [perPageDisplayCount, setPerPageDisplayCount] = useState(50);

  // Repricing
  const [repriceList, setRepriceList] = useState([]);
  const [isRequestingReprice, setIsRequestingReprice] = useState(false);
  const [repriceAction, setRepriceAction] = useState('');
  const [editPriceFor, setEditPriceFor] = useState<{
    id?: string;
    price?: number;
  }>({});

  // Modal
  const [isUpdatingMWSCredentials, setIsUpdatingMWSCredentials] = useState(false);
  const [showConfirmRepriceModal, setShowConfirmRepriceModal] = useState(false);
  const [showStripeSetupModal, setShowStripeSetupModal] = useState(false);
  const [isFetchingInventory, setIsFetchingInventory] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [mwsError, setMwsError] = useState('');
  const [isLoadingPrices, setIsLoadingPrices] = useState(false);

  async function initiate() {
    setFloorProfit(userData.floor_profit >= 1 ? userData.floor_profit : 1);
    if (userData.is_active === 0 || userData.stripe_customer_id === null || userData.stripe_customer_id === '') {
      console.log(`Showing up modal for stripe setup`, userData);
      setShowStripeSetupModal(true);
      console.log(`Here is the auth admin data`, auth.getAdminData());
    } else if (userData.sp_refresh_token === null) {
      console.error(`Amazon account not connected!`, userData);
      setShowGettingStartedModal(true);
    } else {
      // Check version info
      console.info(`Checking system version information. Current version: ${versionId}`);

      fetch(apiBaseUrlLocal + 'sysinfo/version')
        .then((resp) => resp.json())
        .then((versionRes) => {
          if (versionRes && 'data' in versionRes) {
            console.info(`Latest version: ${versionRes.data.val}`);
            if (parseInt(versionRes.data.val) > Number(versionId)) {
              alert(`New version available!`);
              window.location.reload();
            } else {
              // User is on latest version, show popup
              // Check version
              if (userData.version !== Number(versionId)) {
                setUpdateNotes(versionRes.data.notes);
                setShowNewVersionNotification(true);
              }
            }
          }
        });

      loadInventory();
      pullItems();
      getTriggerBatches();
    }
  }

  function loadInventory() {
    setIsLoadingInventory(true);
    callApi(
      'inventory/stats',
      'POST',
      {
        user_id: userData.id,
        app_token: userData.token,
      },
      (response) => {
        setIsLoadingInventory(false);
        if ('data' in response) {
          setInfoBoxesCount(response.data);
        }
      }
    );
  }

  function renderTooltip(props: any) {
    if (undefined !== props && undefined !== props.asin && props.asin !== '') {
      return (
        <Tooltip id="button-tooltip" {...props}>
          {undefined !== props.asin && props.asin !== '' ? (
            <img
              alt="Loading Chart"
              src={`https://repriceiq.threecolts.com/api/scoutiq/chart/${props.asin}?token=${userData.token}`}
            />
          ) : (
            <span>Invalid ASIN</span>
          )}
        </Tooltip>
      );
    } else {
      return (
        <Tooltip id="button-tooltip" {...props}>
          <div>Invalid ASIN</div>
        </Tooltip>
      );
    }
  }

  function renderTooltipSellerInfo(props: { seller_feedback: any; condition: number }) {
    if (props.seller_feedback !== undefined)
      return (
        <Tooltip id="button-tooltip" {...props}>
          <Table bordered>
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    borderBottom: '1px solid #000',
                  }}
                >
                  Seller Feedback
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: 'left' }}>Feedback count</td>
                <td style={{ textAlign: 'left', fontWeight: 'bold' }}>
                  {numberWithCommas(props.seller_feedback.feedback_count)}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: 'left' }}>Positive %</td>
                <td style={{ textAlign: 'left', fontWeight: 'bold' }}>{props.seller_feedback.positive_percentage}</td>
              </tr>
              <tr>
                <td style={{ textAlign: 'left' }}>Condition</td>
                <td style={{ textAlign: 'left', fontWeight: 'bold' }}>{props.condition}</td>
              </tr>
            </tbody>
          </Table>
        </Tooltip>
      );
    else
      return (
        <Tooltip id="button-tooltip" {...props}>
          <span>No data</span>
        </Tooltip>
      );
  }

  function updateCurrentActiveTriggerSet(triggerBatchId: string) {
    setChangingActive(true);
    callApi(
      `triggers/mark-active`,
      'POST',
      {
        user_id: userData.id,
        app_token: userData.token,
        batch_id: triggerBatchId,
      },
      (response) => {
        setChangingActive(false);
        if (response?.status === true) setTriggerBatches(response.data);
        pullItems();
      }
    );
  }

  function updatePerPageDisplayCount(val: string | number) {
    setPerPageDisplayCount(Number(val));
    setIsLoadingInventory(true);
    setItemsInventory([]);

    pullItems();
  }

  function checkUncheck(_index: number) {
    let checkedItemsClone = [...checkedItems];

    const itemToProcess = itemsInventory[_index];
    const itemIndexInCheckedItems = checkedItemsClone.findIndex((checkedItem) => checkedItem.id === itemToProcess.id);

    if (itemIndexInCheckedItems > -1) checkedItemsClone.splice(itemIndexInCheckedItems, 1);
    else checkedItemsClone.push(itemToProcess);

    setCheckedItems(checkedItemsClone);
  }

  function changeInventoryDisplayType(listingView: string) {
    setListingView(listingView);
  }

  function updateFloorProfit(e: UpdateFloorProfitEvent) {
    e.preventDefault();
    const newFloorProfit = e.target.elements.floorProfit.value;
    setIsUpdatingFloorProfit(true);
    callApi(
      `settings/floor-profit`,
      'PUT',
      {
        user_id: userData.id,
        app_token: userData.token,
        floor_profit: newFloorProfit,
      },
      (response) => {
        setIsUpdatingFloorProfit(false);

        if (response && 'status' in response && response.status === true) {
          setFloorProfit(parseFloat(newFloorProfit));
        }
      }
    );
  }

  function pullItems() {
    setPriceLoadProgress(0);
    setIsLoadingInventory(true);

    callApi(
      `inventory/pull/${activePage}`,
      'POST',
      {
        user_id: userData.id,
        app_token: userData.token,
        per_page_limit: perPageDisplayCount,
        order_by: orderBy,
        filters: JSON.stringify({
          searchText: searchText,
          startDate,
          endDate,
          minPrice: minPrice,
          maxPrice: maxPrice,
          fulfillmentChannel: fulfillmentChannel,
          listingView: listingView,
        }),
      },
      (response) => {
        setIsLoadingInventory(false);
        setFetchedLivePrices(false);
        if (response && response.data) {
          setTotalItemsViewCount(response.total_items);
          setItemsInventory(
            response.data.map((data: any) => {
              let tmpData = data;
              tmpData.is_checked = false;
              tmpData.escore = -2;
              tmpData.sales_rank = -2;
              tmpData.buybox = -2;
              tmpData.buybox_used = -2;
              tmpData.buybox_new = -2;
              tmpData.amazon_price = -2;
              tmpData.mf_offers = null;
              tmpData.mf_offers_conditions = null;
              tmpData.fba_offers = null;
              tmpData.fba_offers_conditions = null;
              return tmpData;
            })
          );
        }
      }
    );
  }

  function renderTooltipPriceMatch(matchType: string) {
    return matchType === '' ? (
      <span></span>
    ) : (
      <Tooltip id="button-tooltip">
        <span>{matchType}</span>
      </Tooltip>
    );
  }

  function renderTooltipTriggerInfo(props: { target: any }) {
    /**
     * `Trigger Selected: ${item.target.triggerSelected}.\n\nEstimated Profit: $${item.target.estimatedProfit}\n\nRequired Profit: $${item.target.requiredProfit}`
     */
    return (
      <Tooltip id="button-tooltip" {...props}>
        <Table bordered>
          <tbody>
            <tr>
              <td style={{ textAlign: 'left' }}>Trigger Selected</td>
              <td style={{ textAlign: 'left' }}>{props.target.triggerSelected}</td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>Estimated Profit</td>
              <td style={{ textAlign: 'left' }}>${props.target.estimatedProfit}</td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>Required Profit</td>
              <td style={{ textAlign: 'left' }}>${props.target.requiredProfit}</td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>Total Fee</td>
              <td style={{ textAlign: 'left' }}>
                $
                {undefined !== props.target.totalFee
                  ? (props.target.totalFee + 0.15 * props.target.priceTarget).toFixed(2)
                  : 0}
              </td>
            </tr>
          </tbody>
        </Table>
      </Tooltip>
    );
  }

  function prepareReprice(action: string) {
    setIsRequestingReprice(false);

    if (checkedItems.length > 0 && isLoadingPrices === false) {
      let repriceListClone: any = [];

      for (let i = 0; i < checkedItems.length; i++) {
        if (action === 'custom' && !checkedItems[i].new_price) continue;
        else if ((action === 'target' || action === 'buybox') && checkedItems[i].new_price > 0) continue;

        let repriceObj: RepriceObj = {
          id: checkedItems[i].id,
          asin: checkedItems[i].asin,
          sku: checkedItems[i].sku,
          currentPrice: checkedItems[i].price,
          newPrice: checkedItems[i].price,
          profit: 0,
          originalNewPrice: checkedItems[i].price,
        };

        repriceObj.newPrice =
          action === 'buybox' && checkedItems[i].buybox > 0
            ? checkedItems[i].buybox
            : action === 'target' && checkedItems[i].target.priceTarget > 0
            ? checkedItems[i].target.priceTarget
            : action === 'custom'
            ? checkedItems[i].new_price
            : checkedItems[i].price;

        repriceObj.totalFee =
          undefined !== checkedItems[i].target.totalFee
            ? Number((checkedItems[i].target.totalFee + 0.15 * repriceObj.newPrice).toFixed(2))
            : 0;

        const profit = Number(repriceObj.newPrice - repriceObj.totalFee).toFixed(2);
        const originalNewPrice = Number(repriceObj.newPrice);

        let adjustedNewPrice =
          Number(profit) >= Number(floorProfit)
            ? originalNewPrice
            : Number(floorProfit - Number(profit) + originalNewPrice);

        if (userData.reprice_above_below && !isNaN(Number(userData.reprice_above_below)))
          adjustedNewPrice = adjustedNewPrice + Number(userData.reprice_above_below);

        repriceObj.profit = Number(profit);
        repriceObj.newPrice = adjustedNewPrice;
        repriceObj.originalNewPrice = originalNewPrice;

        repriceListClone.push(repriceObj);
      }

      setRepriceAction(action);
      setRepriceList(repriceListClone);
      setShowConfirmRepriceModal(true);
    }
  }

  async function getLivePrices() {
    let i,
      j,
      temporary,
      chunk = 10;

    let itemsInventoryClone = [...itemsInventory];

    // Setting current timestamp and comparing it later in code will make sure previous request gets cancelled
    const currentTimestamp = new Date().getTime();
    setIsLoadingPrices(true);
    setCurrentLivePriceExeTimestamp(currentTimestamp);

    for (i = 0, j = itemsInventoryClone.length; i < j; i += chunk) {
      temporary = itemsInventoryClone.slice(i, i + chunk);
      const asins = temporary.map((itemData) => {
        return {
          asin: itemData.asin,
          isMF: itemData.is_fba === 0,
          sku: itemData.sku,
          condition: mwsConditionIds[itemData.item_condition].main,
        };
      });
      try {
        setPriceLoadProgress(Number(((i * 100) / j).toFixed(0)));

        let attemptCount = 0;
        while (attemptCount <= 4) {
          console.log(`Loading prices. Page #${i / chunk + 1}. Attempt #${attemptCount + 1}`);
          const itemsPrices = await getPrices(asins);
          if (itemsPrices.error) {
            console.error(`!! Warning !! - Did not receive prices for ${asins.length} items.`, itemsPrices.error);
          } else if (itemsPrices?.data) {
            for (let x = 0; x < itemsPrices.data.length; x++) {
              const itemIndexes = itemsInventory
                .map((item, i) => (item.asin === itemsPrices.data[x].asin ? i : -1))
                .filter((index) => index !== -1);

              if (itemIndexes.length > 0) {
                itemIndexes.forEach((itemIndex) => {
                  if ('totalFee' in itemsPrices.data[x].target) {
                    itemsPrices.data[x].target.estimatedProfitCurrent =
                      itemsInventoryClone[itemIndex].price -
                      (itemsPrices.data[x].target.totalFee + 0.15 * itemsInventoryClone[itemIndex].price);
                  }
                  itemsInventoryClone[itemIndex].target = itemsPrices.data[x].target;
                  itemsInventoryClone[itemIndex].escore = itemsPrices.data[x].escore;
                  itemsInventoryClone[itemIndex].sales_rank = itemsPrices.data[x].sales_rank;
                  itemsInventoryClone[itemIndex].category = itemsPrices.data[x].prices.category;

                  itemsInventoryClone[itemIndex].buybox =
                    itemsInventoryClone[itemIndex].item_condition !== 11
                      ? itemsPrices.data[x].prices.buybox_used
                      : itemsPrices.data[x].prices.buybox_new;
                  itemsInventoryClone[itemIndex].buybox_new = itemsPrices.data[x].prices.buybox_new;
                  itemsInventoryClone[itemIndex].buybox_used = itemsPrices.data[x].prices.buybox_used;
                  itemsInventoryClone[itemIndex].amazon_price = itemsPrices.data[x].prices.amazon_price;

                  if (itemsInventoryClone[itemIndex].item_condition !== 11) {
                    itemsInventoryClone[itemIndex].mf_offers = itemsPrices.data[x].offers.used;
                    itemsInventoryClone[itemIndex].fba_offers = itemsPrices.data[x].offers.fba;
                    itemsInventoryClone[itemIndex].mf_offers_conditions = itemsPrices.data[x].offers.used_desc;
                    itemsInventoryClone[itemIndex].fba_offers_conditions = itemsPrices.data[x].offers.fba_desc;
                  } else {
                    itemsInventoryClone[itemIndex].mf_offers = itemsPrices.data[x].offers.new;
                    itemsInventoryClone[itemIndex].fba_offers = itemsPrices.data[x].offers.fba_new;

                    itemsInventoryClone[itemIndex].mf_offers_conditions = itemsPrices.data[x].offers.new_desc;
                    itemsInventoryClone[itemIndex].fba_offers_conditions = itemsPrices.data[x].offers.fba_new_desc;
                  }
                });
              } else {
                console.log(`${itemsPrices.data[x].asin} not found`);
              }

              setItemsInventory(itemsInventoryClone);
            }
            break;
          } else {
            console.error(`!! Warning !! - INAVLID RESPONSE`, itemsPrices);
          }

          attemptCount += 1;
          // Sleep for 4 seconds before making another attemps
          console.log(`Failed. Sleeping for 2.5 seconds before making another attempt.`);
          await new Promise((resolve) => setTimeout(resolve, 2500));
        }

        if (attemptCount > 4) {
          console.error(`!! Warning !! - Did not receive prices for ${asins.length} items.`);
          setClearRefreshInterval(true);
        }
      } catch (err) {}
    }

    setPriceLoadProgress(100);
    setIsLoadingPrices(false);
    setFetchedLivePrices(true);
  }

  function setNewPrice(itemIndex: number, e: NewPriceFormEvent) {
    e.preventDefault();

    let inInventory = { ...itemsInventory[itemIndex] };
    inInventory.new_price = Number(e.target.elements.newPrice.value);

    let checkedItemsClone = [...checkedItems];
    const inCheckedIndex = checkedItemsClone.findIndex((checkedItem) => checkedItem.id === inInventory.id);
    if (inCheckedIndex !== -1) checkedItemsClone[inCheckedIndex].new_price = Number(e.target.elements.newPrice.value);
    else checkedItemsClone.push(inInventory);

    setCheckedItems(checkedItemsClone);
    setEditPriceFor({});
  }

  async function getPrices(asinList: any): Promise<{
    error?: string;
    data?: any;
  }> {
    return new Promise((resolve, reject) => {
      callApi(
        'amazon/get-prices',
        'POST',
        {
          user_id: userData.id,
          app_token: userData.token,
          asins: JSON.stringify(asinList),
        },
        (response) => {
          console.log(`Recived prices for ${asinList.length} items`);
          resolve(response);
        }
      );
    });
  }

  function confirmReprice() {
    setIsRequestingReprice(true);
    callApi(
      `inventory/reprice`,
      'POST',
      {
        user_id: userData.id,
        app_token: userData.token,
        items_data: JSON.stringify(repriceList),
        reprice_type: repriceAction,
      },
      (response) => {
        setIsRequestingReprice(false);
        if (!response) {
          alert(`Some error occurred`);
        } else {
          setShowConfirmRepriceModal(false);

          if (response.status === true) {
            setCheckedItems([]);
          }
        }
      }
    );
  }

  function getTriggerBatches() {
    setIsLoadingBatches(true);
    callApi(
      `triggers/batches/list`,
      'POST',
      {
        user_id: userData.id,
        app_token: userData.token,
      },
      (response) => {
        setIsLoadingBatches(false);
        if (response && response.status && response.status === true) setTriggerBatches(response.data);
      }
    );
  }

  async function updateUserMWSCredentials(e: UpdateUserMWSCredentialsEvent) {
    e.preventDefault();
    const sellerId = e.target.elements.mwsSellerId.value;
    const authToken = e.target.elements.mwsAuthToken.value;

    setIsUpdatingMWSCredentials(true);

    callApi(
      'settings/update-mws',
      'POST',
      {
        mws_seller_id: sellerId,
        mws_auth_token: authToken,
        user_id: userData.id,
        app_token: userData.token,
      },
      (response) => {
        setIsUpdatingMWSCredentials(false);
        if (response && 'status' in response) {
          if (response.status === true) setIsFetchingInventory(true);
          else setMwsError(response.error);
        } else setMwsError('Unknown Error! Please try again in some time.');
      }
    );
  }

  function refreshInventory() {
    setIsFetchingInventory(true);
    callApi(
      'settings/refresh-inventory',
      'POST',
      {
        user_id: userData.id,
        app_token: userData.token,
      },
      (response) => {
        if ('status' in response) {
          if (response.status !== true) {
            console.error(`Error executing refresh inventory`, response.error);
            setMwsError(response.error);
          }
        } else {
          setMwsError('Unknown Error! Please try again in some time.');
        }
      }
    );
  }

  function updateVersion() {
    callApi(
      `settings/version`,
      'PUT',
      {
        user_id: userData.id,
        app_token: userData.token,
        version: versionId,
      },
      (response) => {
        console.info(`Update version response`, response);
      }
    );
  }

  useEffect(() => {
    initiate();
  }, []);

  useEffect(() => {
    pullItems();
  }, [
    listingView,
    fulfillmentChannel,
    maxPrice,
    minPrice,
    endDate,
    startDate,
    searchText,
    perPageDisplayCount,
    activePage,
  ]);

  useEffect(() => {
    if (
      userData.stripe_customer_id !== null &&
      userData.stripe_customer_id !== '' &&
      fetchedLivePrices === false &&
      itemsInventory.length
    ) {
      getLivePrices();
    }
  }, [fetchedLivePrices]);

  useEffect(() => {
    const refreshInvInterval = setInterval(() => {
      callApi(
        `amazon/report-status`,
        'POST',
        {
          user_id: userData.id,
          app_token: userData.token,
        },
        (response) => {
          if (response && response.status) {
            if (response.status === true) {
              if (response.data.status === 'done') {
                setIsRefreshingInventory(false);
                if (currentInventoryRefreshStatus !== 'done') {
                  setCurrentInventoryRefreshStatus('done');
                  loadInventory();
                  pullItems();
                }
              } else setIsRefreshingInventory(true);
              setCurrentInventoryRefreshStatus(response.data.status);
            }
          }
        }
      );
    }, 5000);

    const pendingFeedInterval = setInterval(() => {
      callApi(
        `amazon/pending-feeds`,
        'POST',
        {
          user_id: userData.id,
          app_token: userData.token,
        },
        (response) => {
          if (response?.status) {
            if (response.data.count < pendingFeeds) {
              loadInventory();
              pullItems();
            }

            setPendingFeeds(response.data.count);
          } else console.log(`Error checking pending Feed`);
        }
      );
    }, 5000);

    if (clearRefreshInterval) {
      clearInterval(refreshInvInterval);
    }

    return () => {
      clearInterval(refreshInvInterval);
      clearInterval(pendingFeedInterval);
    };
  }, [clearRefreshInterval]);

  return (
    <>
      <ModalGettingStarted
        handleClose={() => {}}
        isFetchingInventory={isFetchingInventory}
        mwsError={mwsError}
        isUpdatingMWSCredentials={isUpdatingMWSCredentials}
        updateUserMWSCredentials={updateUserMWSCredentials}
        showGettingStartedModal={showGettingStartedModal}
      />

      <NewVersion
        updateNotes={updateNotes.toString()}
        handleClose={() => {
          setShowNewVersionNotification(false);
          updateVersion();
        }}
        showNewVersionNotification={showNewVersionNotification}
      />

      <ConfirmReprice
        isRequestingReprice={isRequestingReprice}
        confirmReprice={confirmReprice}
        repriceAction={repriceAction}
        repriceList={repriceList}
        showConfirmRepriceModal={showConfirmRepriceModal}
        handleClose={() => {
          setShowConfirmRepriceModal(false);
        }}
      />
      <ModalStripeSetup
        showStripeSetupModal={showStripeSetupModal}
        handleClose={() => {
          setShowStripeSetupModal(false);
          if (userData.mws_seller_id === null || userData.mws_auth_token === null || userData.mws_error === 1)
            setShowGettingStartedModal(true);
          else {
            getLivePrices();
          }
        }}
      />

      <TopMenu isRefreshingInventory={isRefreshingInventory} refreshInventory={refreshInventory} activeKey="/" />
      <DiscontinueBanner />
      <Container className="page-dashboard">
        <Row>
          <Col md={12}>
            <div style={{ display: 'flex' }}>
              <div className="infoboxes">
                <div className="ib-title">Active inventory</div>
                <div className="ib-data-1">
                  {infoBoxesCount.active >= 0 ? (
                    numberWithCommas(infoBoxesCount.active)
                  ) : showGettingStartedModal === true || showStripeSetupModal === true ? (
                    <span> - </span>
                  ) : (
                    <Spinner animation="border" role="status"></Spinner>
                  )}
                </div>
              </div>

              <div className="infoboxes">
                <div className="ib-title">Repriced Today</div>
                <div className="ib-data-1">
                  {infoBoxesCount.repriced_today >= 0 ? (
                    numberWithCommas(infoBoxesCount.repriced_today)
                  ) : showGettingStartedModal === true ? (
                    <span> - </span>
                  ) : (
                    <Spinner animation="border" role="status"></Spinner>
                  )}
                </div>
              </div>

              <div className="infoboxes">
                <div className="ib-title">Repriced this Week</div>
                <div className="ib-data-1">
                  {infoBoxesCount.repriced_week >= 0 ? (
                    numberWithCommas(infoBoxesCount.repriced_week)
                  ) : showGettingStartedModal === true ? (
                    <span> - </span>
                  ) : (
                    <Spinner animation="border" role="status"></Spinner>
                  )}
                </div>
              </div>

              <div className="infoboxes">
                <div className="ib-title">Repriced this Month</div>
                <div className="ib-data-1">
                  {infoBoxesCount.repriced_month >= 0 ? (
                    numberWithCommas(infoBoxesCount.repriced_month)
                  ) : showGettingStartedModal === true ? (
                    <span> - </span>
                  ) : (
                    <Spinner animation="border" role="status"></Spinner>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: '30px' }}>
          <Col md={12}>
            <div className="mainbox">
              <div style={{ display: 'flex' }}>
                {/* <div className="mainbox-options" style={{ flex: 1 }}>
                  <i className="fa fa-gear" />
                </div> */}
                <div className="mainbox-options" style={{ flex: 4, padding: 0 }}>
                  <FormControl
                    disabled={isLoadingPrices}
                    onKeyUp={(target: any) => {
                      if (target.charCode === 13) {
                        setActivePage(1);
                      }
                    }}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    value={searchText}
                    className="invisble-input"
                    type="text"
                    placeholder="SEARCH ..."
                  />
                </div>

                <div
                  className="mainbox-options"
                  style={{
                    flex: 1.5,
                    cursor: 'pointer',
                    backgroundColor: showFilters === true ? '#fff' : '#F8F9FD',
                  }}
                  onClick={() => {
                    setShowFilters(!showFilters);
                  }}
                >
                  <span style={{ fontWeight: 'bold' }}>
                    <i className="fa fa-filter" />
                    <span style={{ marginLeft: '10px' }}>FILTERS</span>
                  </span>
                </div>

                <div style={{ flex: 4, alignSelf: 'center' }}>
                  {pendingFeeds > 0 && (
                    <div>
                      <span style={{ marginLeft: '20px' }}>
                        <img
                          style={{ width: '35px' }}
                          alt="SCOUTIQ"
                          src={require('../assets/animations/loading-gear.gif')}
                        />
                      </span>
                      <span
                        style={{
                          marginLeft: '2px',
                          fontSize: '14px',
                          fontWeight: 500,
                        }}
                      >
                        Repricing {pendingFeeds} items ...
                      </span>
                    </div>
                  )}
                </div>

                <div
                  className="mainbox-options"
                  onClick={() => changeInventoryDisplayType('active')}
                  style={{
                    flex: 2.5,
                    marginLeft: 'auto',
                    cursor: 'pointer',
                    borderLeft: '1px solid #c5c5c4',
                    backgroundColor: listingView === 'active' ? '#53A551' : '#FFFFFF',
                    color: listingView === 'active' ? '#FFFFFF' : '#8d8a8a',
                  }}
                >
                  <span style={{ fontWeight: '500', fontSize: '14px' }}>
                    <i className="fa fa-bookmark" />
                    <span style={{ marginLeft: '10px' }}>
                      ACTIVE {infoBoxesCount.active > 0 ? `(${infoBoxesCount.active})` : ``}
                    </span>
                  </span>
                </div>
                <div
                  onClick={() => changeInventoryDisplayType('inactive')}
                  className="mainbox-options"
                  style={{
                    flex: 2.5,
                    marginLeft: 'auto',
                    cursor: 'pointer',
                    backgroundColor: listingView === 'inactive' ? '#53A551' : '#FFFFFF',
                    color: listingView === 'inactive' ? '#FFFFFF' : '#8d8a8a',
                  }}
                >
                  <span style={{ fontWeight: '500', fontSize: '14px' }}>
                    <i className="fa fa-bookmark-o" />
                    <span style={{ marginLeft: '10px' }}>
                      INACTIVE {infoBoxesCount.inactive > 0 ? `(${infoBoxesCount.inactive})` : ``}
                    </span>
                  </span>
                </div>
                <div
                  onClick={() => changeInventoryDisplayType('stranded')}
                  className="mainbox-options"
                  style={{
                    flex: 2.5,
                    marginLeft: 'auto',
                    cursor: 'pointer',
                    backgroundColor: listingView === 'stranded' ? '#53A551' : '#FFFFFF',
                    color: listingView === 'stranded' ? '#FFFFFF' : '#8d8a8a',
                  }}
                >
                  <span style={{ fontWeight: '500', fontSize: '14px' }}>
                    <i className="fa fa-warning" />
                    <span style={{ marginLeft: '10px' }}>
                      STRANDED {infoBoxesCount.stranded > 0 ? `(${infoBoxesCount.stranded})` : ``}
                    </span>
                  </span>
                </div>
              </div>

              {showFilters && (
                <div
                  style={{
                    padding: '15px 8px 15px 8px',
                    border: '1px solid #e3e3e3',
                    marginBottom: '10px',
                    backgroundColor: '#fff',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ fontWeight: '500' }}>Listed between: </div>
                    <div style={{ marginLeft: '10px', position: 'relative', width: 400, height: 60, zIndex: 2 }}>
                      <DateRange
                        className="date-range-calendar"
                        ranges={[
                          {
                            startDate: startDate.length ? moment(startDate).toDate() : undefined,
                            endDate: endDate.length ? moment(endDate).toDate() : undefined,
                          },
                        ]}
                        onChange={(item) => {
                          setStartDate(item.range1.startDate ? getFormattedDate(item.range1.startDate) : '');
                          setEndDate(item.range1.endDate ? getFormattedDate(item.range1.endDate) : '');
                        }}
                        showMonthAndYearPickers={false}
                        startDatePlaceholder="Start date"
                        endDatePlaceholder="End date"
                      />
                    </div>

                    <div style={{ marginLeft: '20px', fontWeight: '500' }}>Min Price: </div>
                    <div style={{ marginLeft: '10px' }}>
                      <InputGroup>
                        <InputGroup.Text style={{ borderRadius: '0' }} id="per-symbol">
                          $
                        </InputGroup.Text>
                        <FormControl
                          style={{ borderRadius: '0', width: '100px' }}
                          value={minPrice}
                          type="number"
                          step="0.01"
                          onChange={(e) => setMinPrice(e.target.value)}
                        />
                      </InputGroup>
                    </div>

                    <div style={{ marginLeft: '20px', fontWeight: '500' }}>Max Price: </div>
                    <div style={{ marginLeft: '10px' }}>
                      <InputGroup>
                        <InputGroup.Text style={{ borderRadius: '0' }} id="per-symbol">
                          $
                        </InputGroup.Text>
                        <FormControl
                          style={{ borderRadius: '0', width: '100px' }}
                          value={maxPrice}
                          type="number"
                          step="0.01"
                          onChange={(e) => setMaxPrice(e.target.value)}
                        />
                      </InputGroup>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '20px',
                    }}
                  >
                    <div style={{ fontWeight: '500' }}>Fulfillment: </div>
                    <div style={{ marginLeft: '10px' }}>
                      <FormControl
                        as="select"
                        value={fulfillmentChannel}
                        onChange={(e) => setFulfillmentChannel(e.target.value)}
                        style={{ height: '38px', borderRadius: 0 }}
                      >
                        <option value="">Any</option>
                        <option value="amazon">Amazon</option>
                        <option value="merchant">Merchant</option>
                      </FormControl>
                    </div>

                    {/* <div style={{ marginLeft: "20px" }}>Listing View: </div> */}
                    {/* <div style={{ marginLeft: "10px" }}>
                      <FormControl
                        as="select"
                        value={listingView}
                        onChange={(e) => setState({ listingView: e.target.value })}
                        style={{ height: "48px", borderRadius: 0 }}Rich Brian
                      >
                        <option value="">All</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </FormControl>
                    </div> */}
                  </div>

                  <div style={{ marginTop: '30px' }}>
                    <Button
                      variant="warning"
                      type="button"
                      onClick={() => {
                        setMinPrice('');
                        setSearchText('');
                        setStartDate('');
                        setEndDate('');
                        setFulfillmentChannel('');
                        setActivePage(1);
                        setShowFilters(false);
                      }}
                    >
                      Reset Filters
                    </Button>
                  </div>

                  <div style={{ clear: 'both' }}></div>
                </div>
              )}

              <div style={{ border: '1px solid #c5c5c4', padding: '10px' }}>
                <div
                  style={{
                    backgroundColor: '#fff',
                    padding: 8,
                    position: 'sticky',
                    top: 0,
                    zIndex:
                      userData.mws_seller_id !== null && userData.mws_auth_token !== null && userData.mws_error !== 1
                        ? 1
                        : 999,
                    borderRadius: 3,
                    boxShadow: '0 2px 5px rgb(0 0 0 / 0.2)',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        flex: 1,
                        alignSelf: 'flex-start',
                        display: 'flex',
                        paddingTop: '3px',
                      }}
                    >
                      <div>
                        <Button
                          className="reprice-button"
                          variant="info"
                          size="sm"
                          disabled={
                            isLoadingPrices ||
                            checkedItems.reduce((sum, ci) => sum + (ci.new_price > 0 ? 1 : 0), 0) === 0
                          }
                          onClick={() => {
                            prepareReprice('custom');
                          }}
                        >
                          REPRICE CUSTOM{' '}
                          {checkedItems.reduce((sum, ci) => sum + (ci.new_price > 0 ? 1 : 0), 0) > 0 && (
                            <span>({checkedItems.reduce((sum, ci) => sum + (ci.new_price > 0 ? 1 : 0), 0)})</span>
                          )}
                        </Button>
                      </div>
                      <div style={{ marginLeft: '10px' }}>
                        <Button
                          className="reprice-button"
                          variant="secondary"
                          size="sm"
                          disabled={
                            checkedItems.reduce((sum, ci) => sum + (!ci.new_price ? 1 : 0), 0) === 0 || isLoadingPrices
                          }
                          onClick={() => {
                            prepareReprice('target');
                          }}
                        >
                          MATCH TARGET{' '}
                          {checkedItems.reduce((sum, ci) => sum + (!ci.new_price ? 1 : 0), 0) > 0 && (
                            <span>({checkedItems.reduce((sum, ci) => sum + (!ci.new_price ? 1 : 0), 0)})</span>
                          )}
                        </Button>
                      </div>
                      <div style={{ marginLeft: '10px' }}>
                        <Button
                          className="reprice-button"
                          variant="primary"
                          size="sm"
                          disabled={
                            checkedItems.reduce((sum, ci) => sum + (!ci.new_price && ci.buybox > 0 ? 1 : 0), 0) === 0 ||
                            isLoadingPrices
                          }
                          onClick={() => {
                            prepareReprice('buybox');
                          }}
                        >
                          MATCH BUY BOX{' '}
                          {checkedItems.reduce((sum, ci) => sum + (!ci.new_price && ci.buybox > 0 ? 1 : 0), 0) > 0 && (
                            <span>
                              ({checkedItems.reduce((sum, ci) => sum + (!ci.new_price && ci.buybox > 0 ? 1 : 0), 0)})
                            </span>
                          )}
                        </Button>
                      </div>
                    </div>

                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <div style={{ fontSize: '12px', fontWeight: '500' }}>Active Triggers: </div>
                      <div style={{ marginLeft: '5px', width: '190px' }}>
                        <FormControl
                          className="home-page-dd-options-right"
                          disabled={changingActive}
                          as="select"
                          value={triggerBatches.find((tb) => tb.status === 1)?.id ?? ('' || userData.trigger_batch_id)}
                          onChange={(e) => {
                            updateCurrentActiveTriggerSet(e.target.value);
                          }}
                        >
                          {triggerBatches.map((tb) => (
                            <option key={`tbatches-${tb.id}`} value={tb.id}>
                              {tb.name}
                            </option>
                          ))}
                        </FormControl>
                      </div>

                      <div
                        style={{
                          marginLeft: '15px',
                          fontSize: '12px',
                          fontWeight: '500',
                        }}
                      >
                        Floor Profit:{' '}
                      </div>

                      <Form className="form-inline" onSubmit={updateFloorProfit}>
                        <div style={{ marginLeft: '5px' }}>
                          <FormControl
                            className="home-page-dd-options-right"
                            type="number"
                            step="any"
                            name="floorProfit"
                            style={{ width: '80px' }}
                            defaultValue={userData.floor_profit}
                          />
                        </div>

                        <Button type="submit" variant="primary" size="sm" style={{ marginLeft: '5px' }}>
                          <div style={{ width: '38px' }}>
                            {isUpdatingFloorProfit ? (
                              <Spinner
                                style={{ width: '20px', height: '20px' }}
                                animation="border"
                                role="status"
                              ></Spinner>
                            ) : (
                              <span>SAVE</span>
                            )}
                          </div>
                        </Button>
                      </Form>
                    </div>
                  </div>

                  <div
                    style={{
                      marginBottom: '15px',
                      marginTop: '15px',
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        fontSize: '13px',
                        color: '#69A35B',
                        fontWeight: 'bold',
                        flex: 1,
                        alignSelf: 'center',
                      }}
                    >
                      {itemsInventory.length > 0
                        ? `Showing ${(activePage - 1) * perPageDisplayCount} - ${
                            itemsInventory.length === perPageDisplayCount
                              ? activePage * perPageDisplayCount
                              : totalItemsViewCount
                          } of ${totalItemsViewCount} results ${
                            onlyShowCurrentNotTarget !== false
                              ? ` (${
                                  itemsInventory.filter(
                                    (item) =>
                                      item.target !== undefined &&
                                      item.price.toFixed(0) !== item.target.priceTarget.toFixed(0)
                                  ).length
                                } with mismatch prices)`
                              : ``
                          }`
                        : ` ...`}
                    </div>
                    <div
                      style={{
                        alignSelf: 'center',
                        fontWeight: 'bold',
                        color: '#BD4E4E',
                        textAlign: 'right',
                      }}
                    >
                      <FormCheck
                        onChange={(e) => {
                          setOnlyShowCurrentNotTarget(e.target.checked);
                        }}
                        id="custom-switch"
                        label="Current Price not equals Target Price"
                        type="switch"
                      />
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                      <FormControl
                        className="home-page-dd-options-right"
                        as="select"
                        onChange={(e) => {
                          updatePerPageDisplayCount(e.target.value);
                        }}
                        disabled={isLoadingPrices || isLoadingInventory}
                        value={perPageDisplayCount}
                      >
                        {[50, 100, 200, 500, 1000].map((displayCountPP) => {
                          return (
                            <option key={`displaypp-${displayCountPP}`} value={displayCountPP}>
                              Display {displayCountPP !== 1000000000 ? `${displayCountPP}/page` : `All`}
                            </option>
                          );
                        })}
                        );
                      </FormControl>
                    </div>
                  </div>

                  {priceLoadProgress < 100 && (
                    <ProgressBar
                      variant="success"
                      style={{
                        borderRadius: 'none',
                        height: '7px',
                      }}
                      now={priceLoadProgress}
                    />
                  )}
                </div>

                <div style={{ marginTop: '15px' }}>
                  <Table striped bordered style={{ fontSize: '13px' }}>
                    <thead>
                      <tr>
                        <th>
                          <FormCheck
                            style={{
                              paddingLeft: '30px',
                              display: 'flex',
                              height: '100%',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            type="checkbox"
                            checked={
                              itemsInventory.length > 0 &&
                              itemsInventory.every(
                                (itemInventory) =>
                                  checkedItems.findIndex((checkedItem) => checkedItem.id === itemInventory.id) > -1
                              )
                            }
                            onChange={(e) => {
                              let checkedItemsClone = checkedItems;

                              if (e.target.checked) {
                                for (let i = 0; i < itemsInventory.length; i++) {
                                  // Check if only select current price != target price
                                  if (
                                    onlyShowCurrentNotTarget &&
                                    itemsInventory[i].target &&
                                    itemsInventory[i].target.priceTarget.toFixed(0) ===
                                      itemsInventory[i].price.toFixed(0)
                                  )
                                    continue;
                                  // Check if item is not already in checkedItems list, if not
                                  // add it
                                  else if (
                                    checkedItemsClone.findIndex(
                                      (checkedItem) => checkedItem.id === itemsInventory[i].id
                                    ) === -1
                                  )
                                    checkedItemsClone.push(itemsInventory[i]);
                                }
                              } else {
                                for (let i = 0; i < itemsInventory.length; i++) {
                                  checkedItemsClone.splice(
                                    checkedItemsClone.findIndex(
                                      (checkedItem) => checkedItem.id === itemsInventory[i].id
                                    ),
                                    1
                                  );
                                }
                              }
                              setCheckedItems(checkedItemsClone);
                              setAllChecked(e.target.checked);
                            }}
                          />
                        </th>
                        <th
                          style={{
                            textAlign: 'center',
                            minWidth: '60px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            if (!isLoadingPrices && !isLoadingInventory) {
                              setOrderBy(orderBy === 'recently-added' ? 'recently-added-asc' : 'recently-added');

                              setItemsInventory([]);
                              setIsLoadingInventory(true);
                            } else alert('Please wait for prices load to finish');
                          }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>AGE</div>
                            <div
                              style={{
                                fontSize: '16px',
                                paddingLeft: '5px',
                                fontWeight: 'bold',
                              }}
                            >
                              {orderBy === 'recently-added' ? (
                                <span style={{ color: 'blue' }}>&darr;</span>
                              ) : orderBy === 'recently-added-asc' ? (
                                <span style={{ color: 'blue' }}>&uarr;</span>
                              ) : (
                                <span style={{ color: 'black' }}>&#8597;</span>
                              )}
                            </div>
                          </div>
                        </th>
                        <th
                          style={{ minWidth: '95px', cursor: 'pointer' }}
                          onClick={() => {
                            if (!isLoadingPrices && !isLoadingInventory) {
                              setOrderBy(orderBy === 'last-updated' ? 'last-updated-asc' : 'last-updated');
                              setItemsInventory([]);
                              setIsLoadingInventory(true);
                            } else alert('Please wait for prices load to finish');
                          }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>UPDATED</div>
                            <div
                              style={{
                                fontSize: '16px',
                                paddingLeft: '5px',
                                fontWeight: 'bold',
                              }}
                            >
                              {orderBy === 'last-updated' ? (
                                <span style={{ color: 'blue' }}>&darr;</span>
                              ) : orderBy === 'last-updated-asc' ? (
                                <span style={{ color: 'blue' }}>&uarr;</span>
                              ) : (
                                <span style={{ color: 'black' }}>&#8597;</span>
                              )}
                            </div>
                          </div>
                        </th>
                        <th style={{}}>ASIN</th>
                        <th>SKU</th>
                        <th style={{ textAlign: 'center' }}>Category</th>
                        <th style={{}}>TITLE</th>
                        <th
                          style={{
                            textAlign: 'center',
                            minWidth: '95px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            if (!isLoadingPrices && !isLoadingInventory) {
                              setOrderBy(orderBy === 'low-to-high-price' ? 'high-to-low-price' : 'low-to-high-price');
                              setItemsInventory([]);
                              setIsLoadingInventory(true);
                            } else alert('Please wait for prices load to finish');
                          }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>CURRENT</div>
                            <div
                              style={{
                                fontSize: '16px',
                                paddingLeft: '5px',
                                fontWeight: 'bold',
                                color: '#000',
                              }}
                            >
                              {!isLoadingPrices && !isLoadingInventory ? (
                                orderBy === 'low-to-high-price' ? (
                                  <span style={{ color: 'blue' }}>&uarr;</span>
                                ) : orderBy === 'high-to-low-price' ? (
                                  <span style={{ color: 'blue' }}>&darr;</span>
                                ) : (
                                  <span style={{ color: 'black' }}>&#8597;</span>
                                )
                              ) : (
                                <span></span>
                              )}
                            </div>
                          </div>
                        </th>
                        <th
                          style={{
                            textAlign: 'center',
                            borderRight: '1px solid #ddd',
                            minWidth: '95px',
                          }}
                        >
                          TARGET
                        </th>
                        <th style={{ color: '#b9b7b7', textAlign: 'center' }}>
                          eScore
                          <br />
                          RANK
                        </th>
                        <th style={{ color: '#b9b7b7', textAlign: 'center' }}>BUY BOX</th>
                        <th style={{ color: '#b9b7b7', textAlign: 'center' }}>MF</th>
                        <th style={{ color: '#b9b7b7', textAlign: 'center' }}>FBA</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoadingInventory === true ? (
                        <tr>
                          <td style={{ textAlign: 'center' }} colSpan={12}>
                            <Spinner animation="border" role="status"></Spinner>
                          </td>
                        </tr>
                      ) : (
                        itemsInventory.map((item, _index) => {
                          if (!item.item_condition) console.log(`Item with no condition string`, item);
                          const checkedItem = checkedItems.find((checkedItem) => checkedItem.id === item.id);

                          if (
                            onlyShowCurrentNotTarget === false ||
                            (item.target !== undefined && item.price.toFixed(0) !== item.target.priceTarget.toFixed(0))
                          )
                            return (
                              <tr
                                key={`item_${item.id}`}
                                style={{
                                  // backgroundColor:
                                  //   item.is_scheduled === 1 ? "#E7FED3" : item.is_checked === true ? "#dfecff" : "",
                                  backgroundColor:
                                    checkedItems.findIndex((checkedItem) => checkedItem.id === item.id) > -1
                                      ? '#dfecff'
                                      : '',
                                }}
                              >
                                <td>
                                  <FormCheck
                                    style={{
                                      paddingLeft: '30px',
                                      display: 'flex',
                                      height: '100%',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                    onChange={(e) => {
                                      let checkedItemsClone = [...checkedItems];
                                      if (!e.target.checked)
                                        checkedItemsClone.splice(
                                          checkedItemsClone.findIndex((checkedItem) => checkedItem.id === item.id),
                                          1
                                        );
                                      else checkedItemsClone.push(item);

                                      setCheckedItems(checkedItemsClone);
                                      // let itemsInventory = [...itemsInventory];
                                      // itemsInventory[_index].is_checked = e.target.checked;
                                      // if (!itemsInventory[_index].is_checked) delete itemsInventory[_index].new_price;
                                      // setState({ itemsInventory });
                                    }}
                                    type="checkbox"
                                    checked={checkedItems.findIndex((checkedItem) => checkedItem.id === item.id) > -1}
                                  />
                                </td>
                                <td
                                  style={{
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                  }}
                                  onClick={() => {
                                    checkUncheck(_index);
                                  }}
                                >
                                  {getAgeSince(item.open_date) + ' d'}
                                </td>
                                <td
                                  style={{
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                  }}
                                  onClick={() => {
                                    checkUncheck(_index);
                                  }}
                                >
                                  {item.repriced_on !== null ? (
                                    <span>
                                      {timeSince(new Date(item.repriced_on))}
                                      <br />
                                      ago
                                    </span>
                                  ) : (
                                    '-'
                                  )}
                                </td>
                                <td
                                  style={{ cursor: 'pointer', width: '100px' }}
                                  onClick={() => {
                                    checkUncheck(_index);
                                  }}
                                >
                                  {item.asin}
                                </td>
                                <td
                                  style={{
                                    cursor: 'pointer',
                                    width: '190px',
                                    wordBreak: 'break-all',
                                  }}
                                  onClick={() => {
                                    checkUncheck(_index);
                                  }}
                                >
                                  {item.sku}
                                </td>
                                <td
                                  style={{
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                  }}
                                  onClick={() => {
                                    checkUncheck(_index);
                                  }}
                                >
                                  {item.category === undefined || item.category === '' ? '...' : item.category}
                                </td>

                                <td style={{ width: '300px' }}>
                                  <div style={{ marginBottom: '8px' }}>
                                    <a
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      href={`https://www.amazon.com/gp/offer-listing/${
                                        item.asin
                                      }?condition=${mwsConditionIds[item.item_condition].main.toLowerCase()}`}
                                    >
                                      {item.name}
                                    </a>
                                  </div>

                                  <div
                                    style={{
                                      marginBottom: '5px',
                                      marginTop: '3px',
                                      display: 'flex',
                                    }}
                                  >
                                    <div>
                                      <Badge
                                        style={{
                                          background:
                                            mwsConditionIds[item.item_condition].main === 'Used'
                                              ? '#6F757C'
                                              : 'New'
                                              ? '#316CF4'
                                              : '#222529',
                                          color: 'white',
                                          padding: '3px',
                                        }}
                                      >
                                        {mwsConditionIds[item.item_condition].main +
                                          ' ' +
                                          mwsConditionIds[item.item_condition].sub}
                                      </Badge>
                                    </div>

                                    <div>
                                      <Badge
                                        style={{
                                          marginLeft: '10px',
                                          background: item.is_fba === 0 ? '#222529' : '#316CF4',
                                          color: 'white',
                                          padding: '3px',
                                        }}
                                      >
                                        {item.is_fba === 0 ? 'MF' : 'FBA'}
                                      </Badge>
                                    </div>

                                    <div style={{ cursor: 'pointer' }}>
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 0, hide: 100 }}
                                        overlay={renderTooltip({
                                          asin: item.asin,
                                        })}
                                      >
                                        <Badge
                                          style={{
                                            marginLeft: '20px',
                                            padding: '2px',
                                          }}
                                        >
                                          <i style={{ fontSize: '14px' }} className="fa fa-area-chart" />
                                        </Badge>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  {editPriceFor.id === item.id ? (
                                    <div>
                                      <Form onSubmit={(e: NewPriceFormEvent) => setNewPrice(_index, e)}>
                                        <FormControl
                                          autoFocus
                                          className="editprice-input"
                                          required
                                          name="newPrice"
                                          type="number"
                                          step="any"
                                          defaultValue={editPriceFor.price && editPriceFor.price.toFixed(2)}
                                        />
                                        <div style={{ marginTop: '5px' }}>
                                          <Button type="submit" variant="link" className="editprice-buttons">
                                            Save
                                          </Button>
                                        </div>
                                      </Form>
                                      <div style={{ marginTop: '8px' }}>
                                        <Button
                                          onClick={() => setEditPriceFor({})}
                                          variant="link"
                                          className="editprice-buttons"
                                        >
                                          Cancel
                                        </Button>
                                      </div>
                                    </div>
                                  ) : checkedItem !== undefined && checkedItem.new_price > 0 ? (
                                    <div>
                                      <span
                                        style={{
                                          fontSize: '13px',
                                          color: '#646060',
                                        }}
                                      >
                                        <span
                                          style={{
                                            textDecoration: 'line-through',
                                          }}
                                        >
                                          ${item.price.toFixed(2)}
                                        </span>
                                      </span>
                                      <br />
                                      &rarr;
                                      <br />
                                      <span style={{ fontSize: '14px' }}>${checkedItem.new_price}</span>
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() => setEditPriceFor(item)}
                                      className={
                                        item.target !== undefined &&
                                        item.price.toFixed(0) === item.target.priceTarget.toFixed(0)
                                          ? `price-current-match`
                                          : `price-current-unmatch`
                                      }
                                    >
                                      ${item.price.toFixed(2)}
                                    </div>
                                  )}

                                  {item.target !== undefined && (
                                    <div
                                      style={{
                                        marginTop: '5px',
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: item.target.estimatedProfitCurrent > 0 ? '#408558' : '#cb444a',
                                        }}
                                      >
                                        <span>
                                          {item.target.estimatedProfitCurrent > 0 ? (
                                            <span>&uarr;</span>
                                          ) : (
                                            <span>&darr;</span>
                                          )}
                                        </span>{' '}
                                        $
                                        {item.target.estimatedProfitCurrent !== undefined
                                          ? item.target.estimatedProfitCurrent.toFixed(2)
                                          : 0}
                                      </span>
                                    </div>
                                  )}
                                </td>
                                <td
                                  style={{
                                    textAlign: 'center',
                                    borderRight: '1px solid #ddd',
                                  }}
                                >
                                  {item.target !== undefined ? (
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 0, hide: 100 }}
                                      overlay={renderTooltipTriggerInfo({
                                        target: item.target,
                                      })}
                                    >
                                      {/* <div
                                        style={{ fontWeight: "bold", color: "#408558", padding: "6px 3px 6px 3px" }}
                                      >
                                        ${item.target.priceTarget.toFixed(2)}{" "}
                                      </div> */}
                                      <div>
                                        <div
                                          // style={{ marginTop: "5px", fontSize: "12px", fontWeight: "bold" }}
                                          className={
                                            item.target !== undefined &&
                                            item.price.toFixed(0) === item.target.priceTarget.toFixed(0)
                                              ? `price-current-match`
                                              : `price-current-unmatch`
                                          }
                                        >
                                          ${item.target.priceTarget.toFixed(2)}
                                        </div>

                                        {item.target !== undefined && (
                                          <div
                                            style={{
                                              marginTop: '5px',
                                              fontSize: '12px',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            <span
                                              style={{
                                                color: item.target.estimatedProfit > 0 ? '#408558' : '#cb444a',
                                              }}
                                            >
                                              <span>
                                                {item.target.estimatedProfit > 0 ? (
                                                  <span>&uarr;</span>
                                                ) : (
                                                  <span>&darr;</span>
                                                )}
                                              </span>{' '}
                                              ${item.target.estimatedProfit}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </OverlayTrigger>
                                  ) : (
                                    <div>...</div>
                                  )}
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <div
                                    style={{
                                      fontWeight: '600',
                                      color: '#6e6a6a',
                                    }}
                                  >
                                    {item.escore === -2 ? (
                                      <Spinner
                                        style={{
                                          width: '16px',
                                          height: '16px',
                                        }}
                                        animation="grow"
                                        variant="primary"
                                      />
                                    ) : item.escore === -1 ? (
                                      <Badge
                                        style={{
                                          background: 'red',
                                          color: 'white',
                                          padding: '5px',
                                        }}
                                      >
                                        N/A
                                      </Badge>
                                    ) : (
                                      item.escore
                                    )}
                                  </div>

                                  <div
                                    style={{
                                      marginTop: '6px',
                                      color: '#306cf4',
                                      fontWeight: '600',
                                    }}
                                  >
                                    {item.sales_rank === -2 ? (
                                      <Spinner
                                        style={{
                                          width: '16px',
                                          height: '16px',
                                        }}
                                        animation="grow"
                                        variant="primary"
                                      />
                                    ) : item.sales_rank === -1 ? (
                                      <Badge
                                        style={{
                                          background: 'red',
                                          color: 'white',
                                          padding: '5px',
                                        }}
                                      >
                                        N/A
                                      </Badge>
                                    ) : (
                                      nFormatter(item.sales_rank, 0)
                                    )}
                                  </div>
                                </td>

                                <td
                                  style={{
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    color: '#6e6a6a',
                                  }}
                                >
                                  <div>
                                    <div
                                      style={{
                                        fontSize: '9px',
                                        fontWeight: 'bold',
                                        color: '#000',
                                      }}
                                    >
                                      NEW
                                    </div>
                                    {item.buybox_new === -2 ? (
                                      <Spinner
                                        style={{
                                          width: '16px',
                                          height: '16px',
                                        }}
                                        animation="grow"
                                        variant="primary"
                                      />
                                    ) : item.buybox_new === -1 ? (
                                      <Badge
                                        style={{
                                          background: 'red',
                                          color: 'white',
                                          padding: '5px',
                                        }}
                                      >
                                        N/A
                                      </Badge>
                                    ) : (
                                      <div>
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 0, hide: 100 }}
                                          overlay={renderTooltipPriceMatch(
                                            item.buybox_new === item.price
                                              ? 'Your Current Price'
                                              : item.buybox_new === item.amazon_price
                                              ? 'Amazon Price'
                                              : ''
                                          )}
                                        >
                                          <div
                                            className={`offer-prices ${
                                              item.buybox_new === item.price
                                                ? 'my_price_match'
                                                : item.buybox_new === item.amazon_price
                                                ? 'amazon_price_match'
                                                : ''
                                            }`}
                                            style={{ textAlign: 'center' }}
                                            onClick={() => {
                                              let checkedItemsClone = [...checkedItems];

                                              // Check if item exist in checkedItems
                                              const cIndex = checkedItemsClone.findIndex((ci) => ci.id === item.id);
                                              if (cIndex !== -1) checkedItemsClone[cIndex].new_price = item.buybox_new;
                                              else {
                                                let itemNew = { ...item };
                                                itemNew.new_price = item.buybox_new;
                                                checkedItemsClone.push(itemNew);
                                              }
                                              setCheckedItems(checkedItemsClone);
                                              setEditPriceFor({});
                                            }}
                                          >
                                            {'$' + item.buybox_new}
                                          </div>
                                        </OverlayTrigger>
                                      </div>
                                    )}
                                  </div>

                                  <div style={{ marginTop: '20px' }}>
                                    <div
                                      style={{
                                        fontSize: '9px',
                                        fontWeight: 'bold',
                                        color: '#000',
                                      }}
                                    >
                                      USED
                                    </div>
                                    {item.buybox_used === -2 ? (
                                      <Spinner
                                        style={{
                                          width: '16px',
                                          height: '16px',
                                        }}
                                        animation="grow"
                                        variant="primary"
                                      />
                                    ) : item.buybox_used === -1 ? (
                                      <Badge
                                        style={{
                                          background: 'red',
                                          color: 'white',
                                          padding: '5px',
                                        }}
                                      >
                                        N/A
                                      </Badge>
                                    ) : (
                                      <div>
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 0, hide: 100 }}
                                          overlay={renderTooltipPriceMatch(
                                            item.buybox_used === item.price
                                              ? 'Your Current Price'
                                              : item.buybox_used === item.amazon_price
                                              ? 'Amazon Price'
                                              : ''
                                          )}
                                        >
                                          <div
                                            className={`offer-prices ${
                                              item.buybox_used === item.price
                                                ? 'my_price_match'
                                                : item.buybox_used === item.amazon_price
                                                ? 'amazon_price_match'
                                                : ''
                                            }`}
                                            style={{ textAlign: 'center' }}
                                            onClick={() => {
                                              let checkedItemsClone = [...checkedItems];

                                              // Check if item exist in checkedItems
                                              const cIndex = checkedItemsClone.findIndex((ci) => ci.id === item.id);
                                              if (cIndex !== -1) checkedItemsClone[cIndex].new_price = item.buybox_used;
                                              else {
                                                let itemNew = { ...item };
                                                itemNew.new_price = item.buybox_used;
                                                checkedItemsClone.push(itemNew);
                                              }
                                              setCheckedItems(checkedItemsClone);
                                              setEditPriceFor({});
                                            }}
                                          >
                                            {'$' + item.buybox_used}
                                          </div>
                                        </OverlayTrigger>
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  {item.mf_offers === null ? (
                                    <Spinner
                                      style={{ width: '16px', height: '16px' }}
                                      animation="grow"
                                      variant="primary"
                                    />
                                  ) : (
                                    item.mf_offers.map((offer: any, mfIndex: number) => {
                                      return (
                                        //renderTooltipSellerInfo

                                        <OverlayTrigger
                                          key={`ot-mf-offer-${mfIndex}`}
                                          placement="top"
                                          delay={{ show: 0, hide: 100 }}
                                          overlay={renderTooltipSellerInfo({
                                            seller_feedback: item.mf_offers_conditions[mfIndex].seller_feedback,
                                            condition: item.mf_offers_conditions[mfIndex].condition,
                                          })}
                                        >
                                          <div
                                            className={`offer-prices ${
                                              offer === item.price
                                                ? 'my_price_match'
                                                : offer === item.amazon_price
                                                ? 'amazon_price_match'
                                                : ''
                                            }`}
                                            onClick={() => {
                                              let checkedItemsClone = [...checkedItems];

                                              // Check if item exist in checkedItems
                                              const cIndex = checkedItemsClone.findIndex((ci) => ci.id === item.id);
                                              if (cIndex !== -1) checkedItemsClone[cIndex].new_price = offer;
                                              else {
                                                let itemNew = { ...item };
                                                itemNew.new_price = offer;
                                                checkedItemsClone.push(itemNew);
                                              }
                                              setCheckedItems(checkedItemsClone);
                                              setEditPriceFor({});
                                            }}
                                          >
                                            <div style={{ display: 'flex' }}>
                                              <div
                                                style={{
                                                  flex: 1,
                                                  fontWeight: 'bold',
                                                }}
                                              >
                                                {item.mf_offers_conditions[mfIndex].condition.replace(/[a-z]/g, '')}:
                                              </div>
                                              <div style={{ flex: 1 }}>${offer}</div>
                                            </div>
                                          </div>
                                        </OverlayTrigger>
                                      );
                                    })
                                  )}
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  {item.fba_offers === null ? (
                                    <Spinner
                                      style={{ width: '16px', height: '16px' }}
                                      animation="grow"
                                      variant="primary"
                                    />
                                  ) : (
                                    item.fba_offers.map((offer: any, fbaIndex: number) => {
                                      return (
                                        <OverlayTrigger
                                          key={`ot-offer-fba-${fbaIndex}`}
                                          placement="top"
                                          delay={{ show: 0, hide: 100 }}
                                          overlay={renderTooltipSellerInfo({
                                            seller_feedback: item.fba_offers_conditions[fbaIndex].seller_feedback,
                                            condition: item.fba_offers_conditions[fbaIndex].condition,
                                          })}
                                        >
                                          <div
                                            className={`offer-prices ${
                                              offer === item.price
                                                ? 'my_price_match'
                                                : offer === item.amazon_price
                                                ? 'amazon_price_match'
                                                : ''
                                            }`}
                                            onClick={() => {
                                              let checkedItemsClone = [...checkedItems];

                                              // Check if item exist in checkedItems
                                              const cIndex = checkedItems.findIndex((ci) => ci.id === item.id);
                                              if (cIndex !== -1) checkedItems[cIndex].new_price = offer;
                                              else {
                                                let itemNew = { ...item };
                                                itemNew.new_price = offer;
                                                checkedItemsClone.push(itemNew);
                                              }
                                              setCheckedItems(checkedItemsClone);
                                              setEditPriceFor({});
                                            }}
                                          >
                                            <div style={{ display: 'flex' }}>
                                              <div
                                                style={{
                                                  flex: 1,
                                                  fontWeight: 'bold',
                                                }}
                                              >
                                                {item.fba_offers_conditions[fbaIndex].condition.replace(/[a-z]/g, '')}:
                                              </div>
                                              <div style={{ flex: 1 }}>${offer}</div>
                                            </div>
                                          </div>
                                        </OverlayTrigger>
                                      );
                                    })
                                  )}
                                </td>
                              </tr>
                            );
                          else return <></>;
                        })
                      )}
                    </tbody>
                  </Table>
                </div>

                <div
                  style={{
                    marginTop: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Pagination
                    linkClass="page-link"
                    activePage={activePage}
                    itemsCountPerPage={perPageDisplayCount}
                    totalItemsCount={Number(totalItemsViewCount)}
                    pageRangeDisplayed={5}
                    onChange={(pageNo) => {
                      setActivePage(pageNo);
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
