import React from 'react';
import { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Spinner, Form } from 'react-bootstrap';
import { UserData } from '../utils/auth';
import {
  ToTriggerJsonFormEvent,
  addTrigger,
  booksCategoryList,
  deleteTrigger,
  eventToTriggerJson,
  loadTriggers,
  updateTrigger,
} from '../utils/helperTriggers';
import { reconfigTB } from '../utils/helperTriggerBatches';
import { AddTrigger as ModalAddTrigger } from './AddTrigger';
import { EditTriggerProps, EditTrigger as ModalEditTrigger } from './EditTrigger';
import ModalSettings from './SettingsTriggrer';
import { TableListTriggers } from './TableListTriggers';

interface MFFBATriggersProps {
  batchConfiguration: any;
  updateTBConfig(triggerCatName: string, config_amazon_clone: AmazonConfig[]): unknown;
  triggerBatchId: number;
  triggerCatName: string;
  triggersTypeId: number;
  userData: UserData;
}

interface AmazonConfig {
  module_name: string;
  enabled: boolean;
  target_diff: number;
  color: string;
  text: string;
}

export default function MFFBATriggers(props: MFFBATriggersProps) {
  const [triggers, setTriggers] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [editTrigger, setEditTrigger] = useState<EditTriggerProps['editTrigger']>({
    TriggerName: '',
    Id: '',
  });
  const [triggerTypeId, setTriggerTypeId] = useState(-1);
  const [triggerBatchId, setTriggerBatchId] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [isTriggerEnabled, setIsTriggerEnabled] = useState(false);
  const [configAmazon, setConfigAmazon] = useState<AmazonConfig[]>([]);
  const [reconfigInProgress, setReconfigInProgress] = useState(false);
  const [showSettingsModal, setShowSettingModal] = useState(false);
  const domainName = window.location.hostname;

  useEffect(() => {
    function initiateLoadTriggers() {
      setIsLoading(true);
      fetchTriggers();
    }

    async function fetchTriggers() {
      setIsLoading(true);
      const triggersList = await loadTriggers(props.userData, props.triggerBatchId, props.triggersTypeId);
      setTriggers(triggersList.data);
      setReconfigInProgress(false);
      setIsLoading(false);
    }

    if (props.batchConfiguration?.id && props.triggerBatchId && props.triggersTypeId) {
      setTriggerBatchId(props.triggerBatchId);
      setTriggerTypeId(props.triggersTypeId);
      initiateLoadTriggers();

      if (props.triggerCatName in props.batchConfiguration && props.batchConfiguration[props.triggerCatName] !== null) {
        const config_amazon = JSON.parse(props.batchConfiguration[props.triggerCatName]);
        setConfigAmazon(config_amazon);

        const triggerTypeId = props.triggersTypeId;
        const index = config_amazon.findIndex(function (module: { module_name: string }) {
          return module.module_name === (triggerTypeId === 1 || triggerTypeId === 11 ? 'FBA' : 'MF');
        });

        if (index >= 0) {
          const submod_config = config_amazon[index];
          if ('enabled' in submod_config) {
            let target_diff = -1;
            if (submod_config.enabled === true) target_diff = submod_config.target_diff;
            setIsTriggerEnabled(submod_config.enabled);
          }
        }
      }
    }
  }, [props.batchConfiguration]);

  async function onFormSubmitEditTrigger(event: ToTriggerJsonFormEvent) {
    event.preventDefault();
    // Gather required fields
    const triggerId = editTrigger.Id;
    const triggerName = event.target.elements.triggerName.value;
    const triggerJson = eventToTriggerJson(event);
    // Call API to update trigger
    const response = await updateTrigger(
      props.userData,
      triggerBatchId,
      triggerId,
      triggerName,
      triggerJson,
      triggerTypeId
    );
    response !== false && response.status === true && setTriggers(response.data);
    setShowEditModal(false);
  }
  async function onFormSubmitAddTrigger(event: ToTriggerJsonFormEvent) {
    event.preventDefault();
    // Gather required fields
    const triggerName = event.target.elements.triggerName.value;
    const triggerJson = eventToTriggerJson(event);
    // Call API to add trigger
    const response = await addTrigger(props.userData, triggerBatchId, triggerName, triggerJson, triggerTypeId);

    if (response?.status) setTriggers(response.data);
    setShowAddModal(false);
  }

  async function onClickDeleteTrigger(trigger: { Id: string }) {
    // Gather required fields
    const triggerId = trigger.Id;
    // Call API to delete trigger
    const response = await deleteTrigger(props.userData, triggerBatchId, triggerId, triggerTypeId);

    if (response?.status) setTriggers(response.data);

    setShowAddModal(false);
  }

  async function toggleCurrent(e: React.ChangeEvent<HTMLInputElement>) {
    setIsTriggerEnabled(e.target.checked);
    setReconfigInProgress(true);

    let config_amazon_clone = [...configAmazon];

    const index = config_amazon_clone.findIndex(function (module) {
      return module.module_name === (triggerTypeId === 1 || triggerTypeId === 11 ? 'FBA' : 'MF');
    });
    config_amazon_clone[index].enabled = e.target.checked;
    setIsTriggerEnabled(e.target.checked);

    props.updateTBConfig(props.triggerCatName, config_amazon_clone);

    await reconfigTB(props.userData, props.triggerBatchId, props.triggerCatName, JSON.stringify(config_amazon_clone));
    setReconfigInProgress(false);
  }

  function handleEditShow(trigger: any, _index: any) {
    let triggerClone = trigger;
    triggerClone.jsonData = JSON.parse(trigger.TriggerJson);
    triggerClone.categories = booksCategoryList;
    setShowEditModal(true);
    setEditTrigger(triggerClone);
  }

  return (
    <>
      <ModalEditTrigger
        domainName={domainName}
        premiumLevel={props.userData.premium_level}
        showEditModal={showEditModal}
        triggerCatName={props.triggerCatName}
        handleClose={() => setShowEditModal(false)}
        onFormSubmitEditTrigger={onFormSubmitEditTrigger}
        editTrigger={editTrigger}
      />
      <ModalAddTrigger
        domainName={domainName}
        premiumLevel={props.userData.premium_level}
        showAddModal={showAddModal}
        triggerCatName={props.triggerCatName}
        handleClose={() => setShowAddModal(false)}
        onFormSubmitAddTrigger={onFormSubmitAddTrigger}
      />
      <ModalSettings
        admin_data={props.userData}
        showSettingsModal={showSettingsModal}
        triggerBatchId={triggerBatchId}
        triggerTypeId={triggerTypeId}
        handleClose={() => setShowSettingModal(false)}
      />
      {isLoading ? (
        'Loading ... '
      ) : (
        <Container fluid={true} style={{ padding: '0' }}>
          <Row style={{ marginTop: '10px', paddingBottom: '15px' }}>
            <Col md={5}>
              <div style={{ padding: '7px 50px 0 0' }}>
                <Form.Check
                  style={{ paddingTop: '-2em' }}
                  onChange={toggleCurrent}
                  checked={isTriggerEnabled}
                  type="switch"
                  disabled={reconfigInProgress}
                  id="enable-trigger"
                  label="Enable?"
                />
              </div>
            </Col>

            {isTriggerEnabled === true && (
              <Col md={7}>
                <div style={{ textAlign: 'right', marginTop: '5px' }}>
                  <Button
                    className="actionButtonLessPadding"
                    style={{ marginLeft: '8px' }}
                    variant="secondary"
                    onClick={() => setShowSettingModal(true)}
                  >
                    Settings
                  </Button>
                  <Button
                    className="actionButtonLessPadding"
                    style={{ marginLeft: '8px' }}
                    variant="success"
                    onClick={() => setShowAddModal(true)}
                  >
                    Add New Trigger
                  </Button>
                </div>
              </Col>
            )}
          </Row>

          {isTriggerEnabled === true && (
            <Row style={{ marginTop: '20px' }}>
              <Col md={12}>
                {isLoading ? (
                  <div style={{ textAlign: 'center', paddingTop: '100px' }}>
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <TableListTriggers
                    triggerCatName={props.triggerCatName}
                    triggers={triggers}
                    handleEditShow={handleEditShow}
                    deleteTrigger={onClickDeleteTrigger}
                  />
                )}
              </Col>
            </Row>
          )}
        </Container>
      )}
    </>
  );
}
