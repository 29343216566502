import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Table,
  Button,
  Spinner,
  Modal,
  FormControl,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import auth from "../../utils/auth";
import { TopMenu } from "../../components/TopMenu";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { callApi } from "../../utils/helper";
import NewTriggerBatch from "../../components/NewTriggerBatch";
import { ImportTriggerBatch } from "../../components/ImportTriggerBatch";

interface TriggerBatch {
  id: string;
  name: string;
  status: number;
}

const TriggerBatches = () => {
  const [userData] = useState(auth.getAdminData());
  const [triggerBatches, setTriggerBatches] = useState<TriggerBatch[]>([]);
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareCode, setShareCode] = useState("");
  const [isLoadingBatches, setIsLoadingBatches] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [importingTB, setImportingTB] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [sharingTBId, setSharingTBId] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [changingActive, setChangingActive] = useState(false);
  const [deletingTB, setDeletingTB] = useState(false);
  const navigate = useNavigate();

  const createNewTriggerBatch = (batchName: string) => {
    setIsLoadingBatches(true);
    callApi(
      `triggers/new-batch`,
      "POST",
      {
        user_id: userData.id,
        app_token: userData.token,
        batch_name: batchName,
      },
      (response) => {
        setIsLoadingBatches(false);
        if ("status" in response && response.status === true)
          setTriggerBatches(response.data);
      }
    );
  };

  const getTriggerBatches = () => {
    setIsLoadingBatches(true);
    callApi(
      `triggers/batches/list`,
      "POST",
      {
        user_id: userData.id,
        app_token: userData.token,
      },
      (response) => {
        setIsLoadingBatches(false);
        if ("status" in response && response.status === true)
          setTriggerBatches(response.data);
      }
    );
  };

  const handleEditTriggerBatch = (triggerBatch: TriggerBatch) => {
    navigate(`edit-trigger-batch/${triggerBatch.id}`, {
      state: { triggerBatch: triggerBatch },
    });
  };

  const handleShareTriggerBatch = (triggerBatch: TriggerBatch) => {
    setSharingTBId(triggerBatch.id);
    setIsSharing(true);
    callApi(
      `triggers/create-share-hash/${triggerBatch.id}`,
      "POST",
      {
        user_id: userData.id,
        app_token: userData.token,
      },
      (response) => {
        setIsSharing(false);
        if ("status" in response && response.status === true) {
          setShowShareModal(true);
          setShareCode(response.hash);
        }
      }
    );
  };

  const handleDeleteTriggerBatch = (triggerBatch: TriggerBatch) => {
    confirmAlert({
      title: "Delete Trigger Set?",
      message:
        "This will permanently delete this trigger batch. Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setDeletingTB(true);
            callApi(
              `triggers/batch/${triggerBatch.id}`,
              "DELETE",
              {
                user_id: userData.id,
                app_token: userData.token,
              },
              (response) => {
                setDeletingTB(false);
                if ("status" in response && response.status === true)
                  setTriggerBatches(response.data);
              }
            );
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleMarkActive = (triggerBatch: TriggerBatch, index: number) => {
    setChangingActive(true);
    callApi(
      `triggers/mark-active`,
      "POST",
      {
        user_id: userData.id,
        app_token: userData.token,
        batch_id: triggerBatch.id,
      },
      (response) => {
        setChangingActive(false);
        if ("status" in response && response.status === true)
          setTriggerBatches(response.data);
      }
    );
  };

  const onFormSubmitAddTB = (e: any) => {
    e.preventDefault();
    setShowAddModal(false);
    createNewTriggerBatch(e.target.elements.tbName.value);
  };

  const onFormSubmitImportTB = (e: any) => {
    e.preventDefault();
    if (e.target.elements.hashCode.value) {
      setImportingTB(true);
      callApi(
        `triggers/import/batch/${e.target.elements.hashCode.value}`,
        "POST",
        {
          user_id: userData.id,
          app_token: userData.token,
        },
        (response) => {
          setImportingTB(false);
          setShowImportModal(false);
          if (response.status) setTriggerBatches(response.data);
        }
      );
    }
  };

  useEffect(() => {
    getTriggerBatches();
  }, []);

  return (
    <React.Fragment>
      <TopMenu refreshInventory={() => null} activeKey="/" />

      <NewTriggerBatch
        showAddModal={showAddModal}
        handleClose={() => setShowAddModal(false)}
        onFormSubmitAddTB={onFormSubmitAddTB}
      />

      <ImportTriggerBatch
        importingTB={importingTB}
        showImportModal={showImportModal}
        handleClose={() => setShowImportModal(false)}
        onFormSubmitImportTB={onFormSubmitImportTB}
      />

      <Modal
        show={showShareModal}
        onHide={() => setShowShareModal(false)}
        dialogClassName="editModal"
      >
        <Modal.Header closeButton>
          <h3>Share trigger set</h3>
        </Modal.Header>

        <Modal.Body>
          <p>
            Share the following hash code with other NuPrice users to let them
            import (copy) this trigger set.
          </p>
          <FormControl
            style={{ padding: "5px" }}
            disabled={true}
            value={shareCode}
            type="text"
            autoFocus
          />
        </Modal.Body>
      </Modal>

      <Container fluid={true}>
        <Row style={{ marginTop: "20px" }}>
          <Col md={12}>
            <div
              style={{ borderBottom: "1px solid #ddd", paddingBottom: "10px" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    fontSize: "22px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/settings");
                  }}
                >
                  <i
                    onClick={() => {
                      navigate(-1);
                    }}
                    style={{
                      marginRight: "10px",
                      color: "#2E3338",
                      cursor: "pointer",
                    }}
                    className="fa fa-arrow-circle-left "
                  />{" "}
                  NuPrice Settings
                </div>
                <div style={{ marginLeft: "10px" }}>&raquo;</div>
                <div style={{ marginLeft: "10px", fontWeight: "bold" }}>
                  Trigger Batches
                </div>

                <div style={{ marginLeft: "auto" }}>
                  <Button
                    className="actionButtonLessPadding"
                    style={{ marginLeft: "8px" }}
                    variant="primary"
                    onClick={() => setShowImportModal(true)}
                  >
                    Import from hash code
                  </Button>

                  <Button
                    className="actionButtonLessPadding"
                    style={{ marginLeft: "8px" }}
                    variant="success"
                    onClick={() => setShowAddModal(true)}
                  >
                    Create a new trigger set
                  </Button>
                </div>
              </div>
            </div>

            <div style={{ marginTop: "30px" }}>
              <div style={{ marginTop: "30px" }}>
                <Table bordered className="table_triggers">
                  <thead>
                    <tr>
                      <th>Trigger ID</th>
                      <th>Trigger Set Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoadingBatches === false ? (
                      triggerBatches.map((trigger_batch, _index) => (
                        <tr key={trigger_batch.id}>
                          <td>{trigger_batch.id}</td>
                          <td>{trigger_batch.name}</td>

                          <td>
                            <Button
                              className="actionButtonLessPadding"
                              variant="dark"
                              onClick={() =>
                                handleEditTriggerBatch(trigger_batch)
                              }
                            >
                              Edit
                            </Button>

                            {trigger_batch.status === 0 && (
                              <Button
                                disabled={changingActive}
                                className="actionButtonLessPadding"
                                style={{ marginLeft: "8px" }}
                                variant="success"
                                onClick={() =>
                                  handleMarkActive(trigger_batch, _index)
                                }
                              >
                                Set Active
                              </Button>
                            )}

                            {triggerBatches.length > 1 &&
                              trigger_batch.status === 0 && (
                                <Button
                                  disabled={deletingTB}
                                  className="actionButtonLessPadding"
                                  style={{ marginLeft: "8px" }}
                                  variant="danger"
                                  onClick={() =>
                                    handleDeleteTriggerBatch(trigger_batch)
                                  }
                                >
                                  Delete
                                </Button>
                              )}

                            <Button
                              className="actionButtonLessPadding"
                              variant="warning"
                              style={{ marginLeft: "8px", width: "80px" }}
                              onClick={() =>
                                handleShareTriggerBatch(trigger_batch)
                              }
                            >
                              {isSharing && sharingTBId === trigger_batch.id ? (
                                <Spinner
                                  style={{ width: "18px", height: "18px" }}
                                  animation="border"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </Spinner>
                              ) : (
                                <span>Share</span>
                              )}
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3} style={{ textAlign: "center" }}>
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default TriggerBatches;
