import React from 'react';
import { useState, useEffect } from 'react';
import { Table, Button, Spinner } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Elements } from '@stripe/react-stripe-js';

import { UserData } from '../../utils/auth';
import { loadStripe } from '@stripe/stripe-js';
import { callApi } from '../../utils/helper';
import CardForm from '../../components/CardForm';

interface BillingProps {
  userData: UserData;
}

interface SubscriptionInfo {
  nextInvoiceDate: number;
  planId: string;
  subStatus: string;
  cancelAtPeriodEnd: boolean;
  subData: {
    cancel_at: number;
  };
  nextInvoiceAmount: number;
}

const stripePromise = loadStripe(
  'pk_live_51JxYDNFhL1we7j7vKW2SZ3fS1oiKNBCCufHMljP24fMj8yR0GiK0n5BILed5Bw7ObHT9dfd5YiS5UjWykISkeF5u008McbvFx7'
);

export default function Billing(props: BillingProps) {
  const [fetchingPlans, setFetchingPlans] = useState(true);
  const [cancellingSubscription, setCancellingSubscription] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionInfo>();
  const [stripeError, setStripeError] = useState<string>();
  const [isUpdatingCard, setIsUpdatingCard] = useState(false);

  function getUserSubscription() {
    setFetchingPlans(true);
    callApi(
      `stripe/get-subscription`,
      'POST',
      {
        user_id: props.userData.id,
        app_token: props.userData.token,
      },
      (response: any) => {
        setFetchingPlans(false);
        if ('status' in response && response.status === true) setSubscriptionInfo(response.data);
      }
    );
  }

  function cancelSubscription() {
    if (subscriptionInfo) {
      confirmAlert({
        title: 'Are you sure you want to cancel?',
        message: `We're sorry to see you go! If you continue your cancellation it will become effective at the end of your current billing cycle: ${new Date(
          subscriptionInfo.nextInvoiceDate * 1000
        ).toDateString()}. You can rejoin any time - all your reprice data will be archived.`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              setCancellingSubscription(true);
              callApi(
                `stripe/cancel-subscription`,
                'POST',
                {
                  user_id: props.userData.id,
                  app_token: props.userData.token,
                },
                (response) => {
                  setCancellingSubscription(false);
                  if ('status' in response && response.status === true) setSubscriptionInfo(response.data);
                }
              );
            },
          },
          {
            label: 'No',
            onClick: () => {},
          },
        ],
      });
    }
  }

  useEffect(() => {
    getUserSubscription();
  }, []);

  return (
    <>
      <div style={{ padding: '10px' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <div>
              <h5>MANAGE SUBSCRIPTION</h5>
            </div>

            <div style={{ marginTop: '20px' }}>
              <Table style={{ width: 'auto', minWidth: '450px' }}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        borderBottom: '1px solid #ddd',
                        fontSize: '15px',
                      }}
                    >
                      <b>Current Plan</b>
                    </td>
                    <td
                      style={{
                        borderBottom: '1px solid #ddd',
                        fontSize: '15px',
                        minWidth: '200px',
                        textAlign: 'right',
                      }}
                    >
                      {!subscriptionInfo ? (
                        <div style={{ textAlign: 'right' }}>Loading ...</div>
                      ) : subscriptionInfo && subscriptionInfo.planId === 'price_1Jydm6FhL1we7j7voh9Ej99y' ? (
                        'YEARLY'
                      ) : (
                        'MONTHLY'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderBottom: '1px solid #ddd',
                        fontSize: '15px',
                      }}
                    >
                      <b>Subscription Status</b>
                    </td>
                    <td
                      style={{
                        borderBottom: '1px solid #ddd',
                        fontSize: '15px',
                        textAlign: 'right',
                      }}
                    >
                      {!subscriptionInfo ? (
                        <div style={{ textAlign: 'right' }}>Loading ...</div>
                      ) : subscriptionInfo && subscriptionInfo.subStatus === 'active' ? (
                        <span style={{ color: 'green', fontWeight: 'bold' }}>ACTIVE</span>
                      ) : subscriptionInfo && subscriptionInfo.subStatus === 'trialing' ? (
                        <span style={{ color: 'orange', fontWeight: 'bold' }}>TRIAL</span>
                      ) : (
                        <span style={{ color: 'red', fontWeight: 'bold' }}>INACTIVE</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderBottom: '1px solid #ddd',
                        fontSize: '15px',
                      }}
                    >
                      <b>
                        {subscriptionInfo && subscriptionInfo.cancelAtPeriodEnd === true
                          ? 'Cancels on'
                          : 'Next Invoice Date'}
                      </b>
                    </td>
                    <td
                      style={{
                        borderBottom: '1px solid #ddd',
                        fontSize: '15px',
                        textAlign: 'right',
                      }}
                    >
                      {subscriptionInfo === undefined ? (
                        <div style={{ textAlign: 'right' }}>Loading ...</div>
                      ) : subscriptionInfo.cancelAtPeriodEnd === true ? (
                        new Date(subscriptionInfo.subData.cancel_at * 1000).toDateString()
                      ) : (
                        new Date(subscriptionInfo.nextInvoiceDate * 1000).toDateString()
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderBottom: '1px solid #ddd',
                        fontSize: '15px',
                      }}
                    >
                      <b>Next Invoice Amount</b>
                    </td>
                    <td
                      style={{
                        borderBottom: '1px solid #ddd',
                        fontSize: '15px',
                        textAlign: 'right',
                      }}
                    >
                      {subscriptionInfo === undefined ? (
                        <div style={{ textAlign: 'right' }}>Loading ...</div>
                      ) : (
                        '$' + subscriptionInfo.nextInvoiceAmount / 100
                      )}
                    </td>
                  </tr>

                  {subscriptionInfo !== undefined && (
                    <tr>
                      <td colSpan={2} style={{ textAlign: 'right' }}>
                        <Button
                          variant="danger"
                          disabled={cancellingSubscription}
                          onClick={() => {
                            cancelSubscription();
                          }}
                        >
                          {cancellingSubscription === true ? (
                            <Spinner animation="border" role="status">
                              <span className="sr-only">Loading...</span>
                            </Spinner>
                          ) : (
                            'Cancel Subscription'
                          )}
                        </Button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <div>
              <h5>PAYMENT METHOD</h5>
            </div>

            <div style={{ marginTop: '20px' }}>
              Current Card:{' '}
              {'card_brand' in props.userData && props.userData.card_brand !== null ? (
                <span style={{ marginLeft: '10px' }}>
                  <span style={{ fontWeight: 'bold' }}>
                    {props.userData.card_brand && props.userData.card_brand.toUpperCase()}
                  </span>
                  <span style={{ marginLeft: '7px', marginRight: '7px' }}>ending in</span>
                  <span style={{ fontWeight: 'bold' }}>
                    {props.userData.card_last4 && props.userData.card_last4.toString()}
                  </span>
                </span>
              ) : (
                'NO CARD'
              )}
            </div>

            <div style={{ marginTop: '20px' }}>
              <Elements stripe={stripePromise}>
                <CardForm
                  buttonTxt="Update Card"
                  paymentSuccess={() => {
                    alert(`Payment method updated. Page will reload.`);
                    window.location.reload();
                  }}
                  onlyUpdatePaymentMethod={true}
                  updateCardErrorMessage={(err) => setStripeError(err)}
                  isAddingCard={isUpdatingCard}
                  admin_data={props.userData}
                  currentPlan={''}
                  updateAdminData={function (admin_data: any): void {}}
                />
              </Elements>

              {stripeError !== '' && (
                <div
                  style={{
                    color: 'red',
                    marginTop: '30px',
                    fontWeight: 'bold',
                  }}
                >
                  {stripeError}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
