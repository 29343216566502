import React from 'react';
import { useState } from 'react';
import { submitSignup } from '../utils/helper';
import { Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { logoWidth, loginPageEmail, loginPageBanner } from '../configs';

type SignupFormEvent = React.FormEvent<HTMLFormElement> & {
  target: HTMLFormElement & {
    elements: {
      user_email: {
        value: string;
      };
      full_name: {
        value: string;
      };
      password1: {
        value: string;
      };
      password2: {
        value: string;
      };
    };
  };
};

export default function Signup(): JSX.Element {
  const [logingin, setLogingin] = useState(false);
  const [isError, setIsError] = useState(-1);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  async function handleSignup(event: SignupFormEvent) {
    event.preventDefault();
    setIsError(0);

    const fullName = event.target.elements.full_name.value;
    const userEmail = event.target.elements.user_email.value;
    const password1 = event.target.elements.password1.value;
    const password2 = event.target.elements.password2.value;

    if (password1 !== password2) {
      setIsError(1);
      setErrorMessage('Passwords do not match');
    } else if (fullName === '' || userEmail === '') {
      setIsError(1);
      setErrorMessage('Missing name or email');
    } else {
      const response = await submitSignup(fullName, userEmail, password1);
      console.log(response);
      if (response.status === false) {
        setIsError(1);
        setErrorMessage(response.error);
      } else {
        document.location = '/?signup=true';
      }
    }
  }

  return (
    <React.Fragment>
      <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
        <Col md={5} className="div-login-left">
          <div className="div-form-container" style={{ textAlign: 'left', paddingTop: '1vh' }}>
            <div>
              <img
                alt="logo"
                className="logo"
                style={{ width: `${logoWidth}px` }}
                src={require(`../assets/images/nuprice-logo-dark.png`)}
              />
            </div>

            {isError === 1 && (
              <div style={{ marginTop: '30px' }}>
                <Alert variant="danger">
                  <span>{errorMessage}</span>
                </Alert>
              </div>
            )}
            <div>
              <div
                className="welcome-msg-login"
                style={{
                  fontSize: '22px',
                  fontWeight: 'bold',
                  marginTop: '40px',
                  marginBottom: '30px',
                  color: '#4696E4',
                }}
              >
                A few clicks away from creating your NuPrice account.
              </div>

              {successMessage !== '' && <div style={{ color: '#28A745', marginBottom: '20px' }}>{successMessage}</div>}
              <div>
                <Form onSubmit={handleSignup}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label style={{ marginBottom: '0px' }}>Your Name</Form.Label>
                    <Form.Control
                      required
                      autoFocus
                      type="text"
                      autoComplete="off"
                      name="full_name"
                      className="inputNoFormat"
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label style={{ marginBottom: '0px' }}>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      required
                      autoComplete="off"
                      name="user_email"
                      className="inputNoFormat"
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label style={{ marginBottom: '0px' }}>Password</Form.Label>
                    <Form.Control
                      type="password"
                      required
                      autoComplete="off"
                      name="password1"
                      className="inputNoFormat"
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label style={{ marginBottom: '0px' }}>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      required
                      autoComplete="off"
                      name="password2"
                      className="inputNoFormat"
                    />
                  </Form.Group>

                  {logingin ? (
                    <Spinner style={{ marginTop: '33px' }} animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    <Button
                      variant="primary"
                      type="submit"
                      style={{
                        marginTop: '30px',
                        borderRadius: '0',
                        padding: '7px 25px 7px 25px',
                      }}
                    >
                      Create Account
                    </Button>
                  )}
                </Form>
              </div>

              <div className="support-msg" style={{ marginTop: '20px' }}>
                <Button
                  onClick={() => {
                    navigate('/');
                  }}
                  style={{ padding: '0' }}
                  variant="link"
                >
                  Click here to Login
                </Button>
              </div>

              <div className="support-msg">
                For any questions, please email us at <a href={`mailto:${loginPageEmail}`}>{loginPageEmail}</a>
              </div>
            </div>
          </div>
        </Col>
        <Col
          md={7}
          style={{
            background: `url(${require('../assets/images/' + loginPageBanner)}) no-repeat center center`,
            backgroundSize: 'cover',
            backgroundPosition: 'left 0px top 0px',
          }}
        ></Col>
      </Row>
    </React.Fragment>
  );
}
