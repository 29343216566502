import React from 'react';
import auth from '../utils/auth';
import { useNavigate } from 'react-router-dom';

import { Navbar, Nav } from 'react-bootstrap';
import { versionId } from '../configs';

interface TopMenuProps {
  headerTitle?: string;
  isRefreshingInventory?: boolean;
  activeKey?: string;
  refreshInventory: () => void;
}

export function TopMenu(props: TopMenuProps) {
  const navigate = useNavigate();

  function handleLogout() {
    auth.logout(() => {
      navigate('/');
    });
  }

  return (
    <Navbar bg="dark" expand="lg" variant="dark">
      <Navbar.Brand style={{ cursor: 'pointer' }} onClick={() => ((window as Window).location = '/home')}>
        {props.headerTitle !== undefined ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '5px' }}>
              <h3>{props.headerTitle}</h3>
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <img alt="NuPrice" style={{ maxWidth: '75px' }} src={require('../assets/images/nuprice-logo.png')} />
            </div>
            <div style={{ marginLeft: '15px', fontWeight: 'bold' }}>
              <span>REPRICE</span>
              <span style={{ color: '#E48B45', marginLeft: '2px' }}>IQ</span>
            </div>
            <div
              style={{
                marginLeft: '15px',
                fontWeight: 'bold',
                fontSize: '13px',
              }}
            >
              <div>v.{versionId / 10}</div>
            </div>
          </div>
        )}
      </Navbar.Brand>

      <Navbar.Toggle />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto" activeKey={props.activeKey}></Nav>
        <Nav className="mr-auto" activeKey={props.activeKey}></Nav>

        <Nav className="justify-content-end">
          {'refreshInventory' in props &&
            (props.isRefreshingInventory ? (
              <Nav.Item style={{ alignSelf: 'center', marginRight: '20px' }}>
                <span>
                  <img style={{ width: '35px' }} alt="Loading" src={require('../assets/animations/loading-2.gif')} />
                </span>
                <span style={{ color: '#fff' }}>Refreshing inventory ...</span>
              </Nav.Item>
            ) : (
              <Nav.Item style={{ marginRight: '20px' }}>
                <Nav.Link
                  onClick={() => {
                    props.refreshInventory();
                  }}
                  style={{ color: '#fff' }}
                >
                  <i className="fa fa-refresh" />
                  <span style={{ marginLeft: '8px' }}>Refresh Inventory</span>
                </Nav.Link>
              </Nav.Item>
            ))}

          <Nav.Item style={{ marginRight: '20px' }}>
            <Nav.Link
              onClick={() => {
                navigate('/history');
              }}
              style={{ color: '#fff' }}
            >
              <i className="fa fa-history" />
              <span style={{ marginLeft: '8px' }}>History</span>
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link
              onClick={() => {
                navigate('/settings');
              }}
              style={{ color: '#fff' }}
            >
              <i className="fa fa-gear" />
              <span style={{ marginLeft: '8px' }}>User Settings</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
