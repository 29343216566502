import { apiBaseUrlLocal } from "../configs";
import { UserData } from "./auth";

export const getTBConfiguration = async (admin_data: UserData, triggerBatchId: number) => {
  const response = await fetch(apiBaseUrlLocal + "triggers/batch-config/" + triggerBatchId, {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.id,
      app_token: admin_data.token,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const updateTBNameHelper = async (admin_data: UserData, triggerBatchId: number, newTBName: string) => {
  const response = await fetch(apiBaseUrlLocal + "admin/tbatches/updatename/" + triggerBatchId, {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      trigger_batch_name: newTBName,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const checkIfMEnabled = (moduleName: string, batchConfiguration: any) => {
  if (moduleName in batchConfiguration && batchConfiguration[moduleName] !== null) {
    const config_module = JSON.parse(batchConfiguration[moduleName]);
    const index = config_module.findIndex(function (module: { module_name: string }) {
      return module.module_name === moduleName;
    });
    return config_module[index].enabled;
  }
  return false;
};

export const reconfigTB = async (admin_data: UserData, triggerBatchId: number, level: string, config: string) => {
  const response = await fetch(apiBaseUrlLocal + "triggers/reconfig-batch/" + triggerBatchId, {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.id,
      app_token: admin_data.token,
      level: level.toString(),
      config: config,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const validateSBYBEmail = async (admin_data: UserData, sbyb_email: string) => {
  const response = await fetch(apiBaseUrlLocal + "admin/users/update-sbyb", {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      sbyb_email,
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    console.log(data);
    return data;
  } else return false;
};

export const updateVendorsInTB = async (admin_data: UserData, triggerBatchId: number, vendorsList: any[]) => {
  const response = await fetch(apiBaseUrlLocal + "admin/tbatches/bsvendors/" + triggerBatchId, {
    method: "POST",
    body: new URLSearchParams({
      user_id: admin_data.admin_id,
      app_token: admin_data.token,
      vendors_list: JSON.stringify(vendorsList),
    }),
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else return false;
};

export const processTabSequenceChange = async (
  updateTabs: any[],
  module_name: string,
  admin_data: UserData,
  batchConfiguration: Record<string, any>,
  triggerbatch_id: any,
  updateTBConfig: any
) => {
  try {
    if (module_name in batchConfiguration && batchConfiguration[module_name] !== null) {
      const config_module = JSON.parse(batchConfiguration[module_name]);
      let new_config: any[] = [];
      updateTabs.forEach((tabInfo) => {
        const index = config_module.findIndex(function (module: {module_name: string}) {
          return tabInfo.title === module.module_name;
        });
        new_config.push(config_module[index]);
      });
      console.log(new_config);
      updateTBConfig(module_name, new_config);
      await reconfigTB(admin_data, triggerbatch_id, module_name, JSON.stringify(new_config));
      return new_config;
    }
  } catch (error) {
    console.error(error);
  }
};