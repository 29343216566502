import React, { useMemo, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';
import { Button, Spinner } from 'react-bootstrap';
import useResponsiveFontSize from '../hooks/useResponsiveFontSize';
import { apiBaseUrlLocal } from '../configs';

interface CardFormProps {
  paymentSuccess: () => void;
  currentPlan: string;
  updateCardErrorMessage: (error?: string) => void;
  isAddingCard: boolean;
  admin_data: {
    token: string;
    stripe_customer_id: any;
    admin_id: string;
    stripe_payment_id: string;
    card_last4?: string;
    card_brand?: string;
  };
  updateAdminData: (admin_data: any) => void;
  buttonTxt?: string;
  onlyUpdatePaymentMethod?: boolean;
}

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [fontSize]
  );

  return options;
};

export default function CardForm({
  admin_data,
  updateAdminData,
  updateCardErrorMessage,
  currentPlan,
  paymentSuccess,
  buttonTxt,
  onlyUpdatePaymentMethod,
}: CardFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [isUpdatingCard, setIsUpdatingCard] = useState(false);

  const handleUpdatePayment = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setIsUpdatingCard(true);
    updateCardErrorMessage('');
    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement) as StripeCardElement,
    });

    if ('error' in payload) {
      updateCardErrorMessage(payload.error?.message);
      setIsUpdatingCard(false);
    } else {
      const cardLast4 = payload.paymentMethod.card?.last4;
      const paymentId = payload.paymentMethod.id;
      const cardBrand = payload.paymentMethod.card?.brand;

      admin_data.stripe_payment_id = paymentId;
      admin_data.card_last4 = cardLast4;
      admin_data.card_brand = cardBrand;

      try {
        const response = await fetch(apiBaseUrlLocal + 'stripe/payment_id', {
          method: 'PUT',
          body: new URLSearchParams({
            user_id: admin_data.admin_id,
            app_token: admin_data.token,
            payment_id: paymentId,
            card_last4: cardLast4 || '',
            card_brand: cardBrand || '',
          }),
        });

        if (response.status === 200) {
          if (onlyUpdatePaymentMethod === true) {
            paymentSuccess();
          } else {
            const data = await response.json();
            admin_data.stripe_customer_id = data.stripe_customer_id;

            const responseSub = await fetch(apiBaseUrlLocal + 'stripe/update-subscription', {
              method: 'PUT',
              body: new URLSearchParams({
                user_id: admin_data.admin_id,
                app_token: admin_data.token,
                plan_name: currentPlan,
              }),
            });

            if (responseSub.status === 200) {
              admin_data && updateAdminData(admin_data);
              paymentSuccess();
            } else if (responseSub.status === 400) {
              const data = await response.json();
              updateCardErrorMessage(data.error);
            } else {
              updateCardErrorMessage(`Unexpected error`);
            }
          }
        } else if (response.status === 400) {
          const data = await response.json();
          updateCardErrorMessage(data.error);
        } else {
          updateCardErrorMessage(`Unexpected error`);
          console.error(response);
        }
      } catch (error) {
        console.error(`Cannot attach payment method`, error);
      }

      setIsUpdatingCard(false);
    }
  };

  return (
    <>
      <CardElement options={options} />

      <Button variant="primary" style={{ marginTop: '20px' }} onClick={handleUpdatePayment}>
        {isUpdatingCard === true ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : buttonTxt !== '' ? (
          buttonTxt
        ) : (
          'Start Subscription'
        )}
      </Button>
    </>
  );
}
