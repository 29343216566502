import { useState, useEffect } from 'react';
import { validateInviteToken, submitResetPassword } from '../utils/helper';
import { Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap';
import { logoWidth, loginPageBanner, loginPageEmail } from '../configs';

export default function ResetPassword() {
  const [logingin, setLogingin] = useState<boolean>(false);
  const [isInvalidLogin, setIsInvalidLogin] = useState<number>(-1);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [invalidLoginMessage, setInvalidLoginMessage] = useState<string>('');
  const [validatingToken, setValidatingToken] = useState<boolean>(true);
  const [appToken, setappToken] = useState<string>('');
  const [invalidToken, setInvalidToken] = useState<boolean>(false);

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let appToken = params.get('token') as unknown as string;

    if (appToken) {
      setappToken(appToken);
      validateToken(appToken);
    }
  }, []);

  const validateToken = async (token: string) => {
    setValidatingToken(true);

    const response = await validateInviteToken(token);
    if (response?.status === true) setInvalidToken(false);
    else setInvalidToken(true);

    setValidatingToken(false);
  };

  const handleResetPassword = async (event: any) => {
    event.preventDefault();
    const user_password = event.target.elements.user_password.value;
    const user_password_2 = event.target.elements.user_password_2.value;

    if (user_password !== user_password_2) {
      setIsInvalidLogin(1);
      setInvalidLoginMessage('Passwords do not match');
    } else {
      setLogingin(true);
      const response = await submitResetPassword(appToken, user_password_2);
      setLogingin(false);

      if (response?.status === false) {
        setIsInvalidLogin(1);
        setInvalidLoginMessage(response.error);
      } else setIsSuccess(true);
    }
  };

  return (
    <>
      {validatingToken === true ? (
        <div style={{ marginTop: '100px', textAlign: 'center' }}>
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : validatingToken === false && invalidToken === false ? (
        <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
          <Col md={5} className="div-login-left">
            <div className="div-form-container">
              <div>
                <img
                  alt="logo"
                  className="logo"
                  style={{ width: `${logoWidth}px` }}
                  src={require(`../assets/images/nuprice-logo-dark.png`)}
                />
              </div>

              {isInvalidLogin === 1 && (
                <div style={{ marginTop: '20px' }}>
                  <Alert variant="danger">
                    <span>{invalidLoginMessage}</span>
                  </Alert>
                </div>
              )}
              <div>
                <div className="welcome-msg-login">
                  <h4>Create New Password</h4>
                </div>

                {isSuccess && (
                  <div style={{ color: '#28A745', marginBottom: '20px' }}>
                    <Button
                      onClick={() => {
                        window.location.href = '/';
                      }}
                      style={{ padding: '0', color: 'green' }}
                      variant="link"
                    >
                      Password Reset Successful! Click here to login
                    </Button>
                  </div>
                )}

                <Form onSubmit={handleResetPassword}>
                  <Form.Group style={{ marginTop: '50px' }} controlId="formBasicPassword">
                    <Form.Label style={{ marginBottom: '0px' }}>Enter Password</Form.Label>
                    <Form.Control
                      style={{ width: '100%', textAlign: 'center' }}
                      type="password"
                      name="user_password"
                      className="inputNoFormat"
                    />
                  </Form.Group>

                  <Form.Group style={{ marginTop: '50px' }} controlId="formBasicPassword">
                    <Form.Label style={{ marginBottom: '0px' }}>Confirm Password</Form.Label>
                    <Form.Control
                      style={{ width: '100%', textAlign: 'center' }}
                      type="password"
                      name="user_password_2"
                      className="inputNoFormat"
                    />
                  </Form.Group>

                  {logingin ? (
                    <Spinner style={{ marginTop: '33px' }} animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    <Button
                      variant="primary"
                      type="submit"
                      style={{ marginTop: '30px', borderRadius: '0', padding: '7px 25px 7px 25px' }}
                    >
                      Change Password
                    </Button>
                  )}
                </Form>

                <div className="support-msg">For any questions, please email us at {loginPageEmail}</div>
              </div>
            </div>
          </Col>
          <Col
            md={7}
            style={{
              background: `url(${require('../assets/images/' + loginPageBanner)}) no-repeat center center`,
              backgroundSize: 'cover',
              backgroundPosition: 'left 0px top 0px',
            }}
          ></Col>
        </Row>
      ) : (
        <div style={{ color: 'red', marginTop: '50px', textAlign: 'center' }}>Invalid Token</div>
      )}
    </>
  );
}
