import React from 'react';
import { useState } from 'react';
import { Form, Modal, Button, Spinner } from 'react-bootstrap';
import { apiBaseUrlLocal } from '../configs';

interface SettingTriggersProps {
  showSettingsModal: boolean;
  triggerBatchId: number;
  admin_data: {
    id: string;
    token: string;
  };
  triggerTypeId: number;
  handleClose: () => void;
}

type SettingsFormEvent = React.FormEvent<HTMLFormElement> & {
  target: HTMLFormElement & {
    elements: {
      fallbackVal: {
        value: string;
      };
      fallbackValFixed: {
        value: string;
      };
    };
  };
};

interface Settings {
  no_used_fallback_val?: string;
  no_used_fallback_val_fixed?: string;
}

export default function SettingTriggers(props: SettingTriggersProps) {
  const [noUsedFallback, setNoUsedFallback] = useState(false);
  const [settings, setSetttings] = useState<Settings>({});
  const [isLoadingSettings, setIsLoadingSettings] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  async function fetchSettings() {
    setIsLoadingSettings(true);
    console.log(props.admin_data);
    const response = await fetch(`${apiBaseUrlLocal}triggers/batch-settings/${props.triggerBatchId}`, {
      method: 'POST',
      body: new URLSearchParams({
        user_id: props.admin_data.id,
        app_token: props.admin_data.token,
        trigger_type: props.triggerTypeId.toString(),
      }),
    });

    if (response.status === 200) {
      const settings_raw = await response.json();
      const noUsedFallback = 'no_used_fallback_val' in settings_raw.data ? false : true;
      setSetttings(settings_raw.data);
      setNoUsedFallback(noUsedFallback);
      setIsLoadingSettings(false);
    }
  }

  async function onFormSubmitSaveSettings(event: SettingsFormEvent) {
    event.preventDefault();
    const fallbackVal =
      noUsedFallback === true
        ? ''
        : 'fallbackVal' in event.target.elements
        ? event.target.elements.fallbackVal.value
        : '';
    const fallbackValFixed =
      'fallbackValFixed' in event.target.elements ? event.target.elements.fallbackValFixed.value : '';

    setIsSaving(true);

    const response = await fetch(`${apiBaseUrlLocal}triggers/batch-settings/${props.triggerBatchId}`, {
      method: 'PUT',
      body: new URLSearchParams({
        user_id: props.admin_data.id,
        app_token: props.admin_data.token,
        trigger_type: props.triggerTypeId.toString(),
        fallback_val: fallbackVal,
        fallback_val_fixed: fallbackValFixed,
      }),
    });
    setIsSaving(false);

    if (response.status === 200) {
      props.handleClose();
    } else return false;
  }

  function handleOpen() {
    fetchSettings();
  }

  function toggleFallback(event: React.ChangeEvent<HTMLInputElement>) {
    setNoUsedFallback(event.target.checked);
  }

  return (
    <Modal onEntered={handleOpen} show={props.showSettingsModal} onHide={props.handleClose} dialogClassName="editModal">
      <Modal.Header closeButton>
        <h3>Settings</h3>
      </Modal.Header>
      {isLoadingSettings === true ? (
        <div style={{ padding: '50px' }}>
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : (
        <Form onSubmit={onFormSubmitSaveSettings}>
          <Modal.Body>
            <div>
              <Form.Check
                type="switch"
                name="isActive"
                onChange={toggleFallback}
                defaultChecked={noUsedFallback}
                id="is-active"
                style={{ fontWeight: 'bold' }}
                label="Disable Missing Offers Trigger?"
              />
            </div>
            {noUsedFallback === false && (
              <div>
                <div style={{ marginTop: '25px' }}>
                  <div style={{ float: 'left', padding: '5px 5px 5px 0' }}>
                    If there are no used offers, set the target price to be{' '}
                  </div>
                  <div style={{ float: 'left' }}>
                    <Form.Control
                      required
                      type="number"
                      step="0.01"
                      name="fallbackVal"
                      max={100}
                      style={{
                        width: '80px',
                        borderRadius: '0',
                        border: 'none',
                        borderBottom: '1px solid #ccc',
                      }}
                      defaultValue={'no_used_fallback_val' in settings ? settings.no_used_fallback_val : ''}
                    />
                  </div>
                  <div style={{ float: 'left', padding: '5px 0 5px 0' }}>% of the lowest new price</div>
                  <div style={{ clear: 'both' }}></div>
                </div>

                <div style={{ marginTop: '15px' }}>
                  <div style={{ float: 'left', padding: '5px 5px 5px 0' }}>
                    If there are no new offers either, set the target price to be a fixed price of $
                  </div>
                  <div style={{ float: 'left' }}>
                    <Form.Control
                      required
                      type="number"
                      step="0.01"
                      name="fallbackValFixed"
                      max={100}
                      style={{
                        width: '80px',
                        borderRadius: '0',
                        border: 'none',
                        borderBottom: '1px solid #ccc',
                      }}
                      defaultValue={'no_used_fallback_val_fixed' in settings ? settings.no_used_fallback_val_fixed : ''}
                    />
                  </div>

                  <div style={{ clear: 'both' }}></div>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {isSaving === true ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            )}
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  );
}
