import { Container } from "react-bootstrap";

export function DiscontinueBanner() {
  return (
    <div
      style={{
        backgroundColor: "#3c3e41",
        textAlign: "center",
        color: "white",
        fontWeight: "500",
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
    >
      <Container>
        <div className="discontinue-banner">
          <div className="discontinue-banner__content">
            <h4>
              ATTENTION{" "}
              <img
                alt="NuPrice"
                style={{ maxWidth: "50px", paddingRight: "10px" }}
                src={require("../assets/images/attention-triangle.png")}
              />
            </h4>
            <p className="discontinue-banner__title">
              <img
                alt="NuPrice"
                style={{ maxWidth: "50px", paddingRight: "10px" }}
                src={require("../assets/images/nuprice-logo.png")}
              />
              <span style={{ fontWeight: "bolder" }}>REPRICE</span>
              <span style={{ fontWeight: "bolder", color: "#438148" }}>
                IQ{" "}
              </span>
              is discontinuing and will no longer be available as of 04.01.2024
            </p>
            <p className="discontinue-banner__message">
              Exisitng customers, we have an exlusive deal for you. Lock in your
              current rate by switching to SmartRepricer before March 31st 2024.
              Please reach out to us for more details at{" "}
              <a href="mailto:support@nuprice.co">support@nuprice.co</a>
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
}
