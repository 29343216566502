import React from 'react';
import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Tabs, Tab, DragTabList, PanelList, Panel, helpers } from '@react-tabtab-next/tabtab';
import auth from '../utils/auth';
import MFFBATriggers from './MFFBATriggers';
import { processTabSequenceChange } from '../utils/helperTriggerBatches';
import * as css2 from '../utils/tabStyle';

interface AmazonTriggersProps {
  triggerBatchId: number;
  triggersType: string;
  updateTBConfig: any;
  batchConfiguration: any;
}

export default function AmazonTriggers(props: AmazonTriggersProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [tabsTemplate, setTabsTemplate] = useState<JSX.Element[]>([]);
  const [panelTemplate, setPanelTemplate] = useState<JSX.Element[]>([]);
  const userData = auth.getAdminData();

  useEffect(() => {
    if (props.batchConfiguration) {
      const tabs = getTabs();

      setTabsTemplate(tabs.map((tab, index) => <Tab key={index}>{tab.title}</Tab>));

      setPanelTemplate(tabs.map((tab, index) => <Panel key={index}>{tab.content}</Panel>));
    }
  }, [props.batchConfiguration]);

  function getTabs() {
    let tabs = [
      {
        title: `FBA`,
        content: (
          <MFFBATriggers
            triggersTypeId={props.triggersType.includes('_used') ? 1 : 11}
            triggerCatName={props.triggersType}
            updateTBConfig={props.updateTBConfig}
            batchConfiguration={props.batchConfiguration}
            userData={userData}
            triggerBatchId={props.triggerBatchId}
          />
        ),
      },
      {
        title: `MF`,
        content: (
          <MFFBATriggers
            triggersTypeId={props.triggersType.includes('_new') ? 2 : 21}
            triggerCatName={props.triggersType}
            updateTBConfig={props.updateTBConfig}
            batchConfiguration={props.batchConfiguration}
            userData={userData}
            triggerBatchId={props.triggerBatchId}
          />
        ),
      },
    ];

    let tabsInOrder: typeof tabs = [];

    const amazonBatchConfiguration =
      props.triggersType in props.batchConfiguration && props.batchConfiguration[props.triggersType] !== ''
        ? JSON.parse(props.batchConfiguration[props.triggersType])
        : [];

    amazonBatchConfiguration.forEach((tabName: { module_name: string }) => {
      var index = tabs.findIndex(function (tab) {
        return tab.title === tabName.module_name;
      });

      if (index >= 0) tabsInOrder.push(tabs.splice(index, 1)[0]);
    });
    tabsInOrder = tabsInOrder.concat(tabs);
    return tabsInOrder;
  }

  async function handleTabSequenceChange({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) {
    const updateTabs = helpers.simpleSwitch(getTabs(), oldIndex, newIndex);
    setActiveIndex(newIndex);

    await processTabSequenceChange(
      updateTabs,
      props.triggersType,
      userData,
      props.batchConfiguration,
      props.triggerBatchId,
      props.updateTBConfig
    );
  }

  return (
    <>
      <Container fluid={true} style={{ padding: '0' }}>
        <Row>
          <Col md={12}>
            {tabsTemplate.length === 0 ? (
              <h1>LOADING ...</h1>
            ) : (
              <>
                <Tabs
                  customStyle={css2}
                  showArrowButton={false}
                  activeIndex={activeIndex}
                  onTabChange={(index) => setActiveIndex(index)}
                  onTabSequenceChange={handleTabSequenceChange}
                >
                  <DragTabList>{tabsTemplate}</DragTabList>
                  <PanelList>{panelTemplate}</PanelList>
                </Tabs>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
