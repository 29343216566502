import React from 'react';
import { Modal, Button, Spinner, Table } from 'react-bootstrap';

interface ConfirmRepriceProps {
  showConfirmRepriceModal: boolean;
  handleClose: () => void;
  repriceAction: string;
  confirmReprice: () => void;
  isRequestingReprice: boolean;
  repriceList: any[];
}

export function ConfirmReprice(props: ConfirmRepriceProps): JSX.Element {
  function handleOpen() {}
  return (
    <>
      <Modal
        onEntered={handleOpen}
        show={props.showConfirmRepriceModal}
        onHide={props.handleClose}
        dialogClassName="editModal"
      >
        <Modal.Header closeButton>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <h3>
              Confirm reprice (Current Price &rarr;{' '}
              <span style={{ textTransform: 'capitalize' }}>{props.repriceAction}</span> Price)
            </h3>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: 'center', marginBottom: '10px' }}>
            <Button
              variant="primary"
              type="button"
              onClick={() => props.confirmReprice()}
              disabled={props.isRequestingReprice}
            >
              {props.isRequestingReprice === true ? (
                <Spinner animation="border" role="status"></Spinner>
              ) : (
                <span> Confirm/Schedule Reprice {props.repriceList.length} items</span>
              )}
            </Button>
          </div>
          <Table bordered>
            <thead>
              <tr>
                <th>SKU</th>
                <th>ASIN</th>
                <th style={{ textAlign: 'center' }}>Current Price</th>
                <th style={{ textAlign: 'center' }}>New Price</th>
                <th style={{ textAlign: 'center' }}>Profit</th>
                <th style={{ textAlign: 'center' }}>Fees</th>
                <th style={{ textAlign: 'center' }}>Adjusted New Price (Floor)</th>
              </tr>
            </thead>
            <tbody>
              {props.repriceList.map((item) => {
                return (
                  <tr>
                    <td>{item.sku}</td>
                    <td>{item.asin}</td>
                    <td style={{ textAlign: 'center' }}>${item.currentPrice.toFixed(2)}</td>
                    <td style={{ textAlign: 'center' }}>${item.originalNewPrice.toFixed(2)}</td>
                    <td style={{ textAlign: 'center' }}>${item.profit}</td>
                    <td style={{ textAlign: 'center' }}>${item.totalFee}</td>
                    <td style={{ textAlign: 'center' }}>${item.newPrice.toFixed(2)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
}
