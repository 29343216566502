import React from 'react';
import { Row, Col, Form, Modal, Button, InputGroup } from 'react-bootstrap';
import { useState } from 'react';
import { ToTriggerJsonFormEvent } from '../utils/helperTriggers';

export interface EditTriggerProps {
  editTrigger: {
    jsonData?: {
      ceiling_1_option: string;
      ceiling_2_option: string;
      ceiling_3_option: string;
      ceiling_keepa_option: string;
      min_escore: string;
      is_mm: string | boolean;
      max_escore?: string;
      min_rank?: string;
      max_rank?: string;
      fba_slot?: string;
      new_slot?: string;
      used_slot?: string;
      amz_off_per?: string;
      target_profit?: string;
      ceiling_1_discount?: string;
      ceiling_3_bump_up_dollars?: string;
      ceiling_3_bump_up_percentage?: string;
      ceiling_2_bump_up_dollars?: string;
      ceiling_keepa_bump_up_dollars?: string;
      ceiling_2_bump_up_percetange?: string;
      ceiling_keepa_bump_up_percentage?: string;
      mm_val?: string;
      always_reject: any;
      bb_compare: any;
    };
    TriggerName: string;
    Id: string;
  };
  showEditModal: boolean;
  handleClose: () => void;
  onFormSubmitEditTrigger: (event: ToTriggerJsonFormEvent) => Promise<void>;
  triggerCatName: string;
  premiumLevel: number;
  domainName: string;
}

export function EditTrigger(props: EditTriggerProps) {
  const [isCeiling1Enabled, setIsCeiling1Enabled] = useState(false);
  const [isCeiling2Enabled, setIsCeiling2Enabled] = useState(false);
  const [isCeiling3Enabled, setIsCeiling3Enabled] = useState(false);
  const [isCeilingKeepaEnabled, setIsCeilingKeepaEnabled] = useState<boolean | string>(false);
  const [isMMEnabled, setIsMMEnabled] = useState(false);

  function handleC1Change(event: React.ChangeEvent<HTMLInputElement>): void {
    setIsCeiling1Enabled(event.target.checked);
  }
  function handleC2Change(event: React.ChangeEvent<HTMLInputElement>) {
    setIsCeiling2Enabled(event.target.checked);
  }
  function handleC3Change(event: React.ChangeEvent<HTMLInputElement>) {
    setIsCeiling3Enabled(event.target.checked);
  }
  function handleCKeepaChange(event: React.ChangeEvent<HTMLInputElement>) {
    setIsCeilingKeepaEnabled(event.target.checked);
  }

  function handleOpen() {
    if (props.editTrigger.jsonData) {
      const { ceiling_1_option, ceiling_2_option, ceiling_3_option } = props.editTrigger.jsonData;
      setIsCeiling1Enabled(Boolean(ceiling_1_option));
      setIsCeiling2Enabled(Boolean(ceiling_2_option));
      setIsCeiling3Enabled(Boolean(ceiling_3_option));
      setIsCeilingKeepaEnabled(
        'ceiling_keepa_option' in props.editTrigger.jsonData ? props.editTrigger.jsonData.ceiling_keepa_option : ''
      );
      setIsMMEnabled(
        'ceiling_keepa_option' in props.editTrigger.jsonData ? Boolean(props.editTrigger.jsonData.is_mm) : false
      );
    }
  }

  return (
    <Modal onEntered={handleOpen} show={props.showEditModal} onHide={props.handleClose} dialogClassName="editModal">
      <Form onSubmit={props.onFormSubmitEditTrigger}>
        <Modal.Header closeButton>
          <Form.Control
            required
            type="text"
            name="triggerName"
            className="input-text-trigger-name"
            defaultValue={props.editTrigger !== undefined ? props.editTrigger.TriggerName : ''}
            placeholder="Enter Trigger Name Here"
          />
        </Modal.Header>
        <Modal.Body>
          {undefined !== props.editTrigger && 'jsonData' in props.editTrigger && (
            <div style={{ fontSize: '13px', fontWeight: '500' }}>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formMinScore">
                        <Form.Label>Min eScore</Form.Label>
                        <Form.Control
                          required
                          type="number"
                          step="any"
                          name="minEscore"
                          placeholder="Min eScore"
                          defaultValue={
                            !!props.editTrigger.jsonData &&
                            'min_escore' in props.editTrigger.jsonData &&
                            props.editTrigger.jsonData.min_escore !== ''
                              ? props.editTrigger.jsonData.min_escore
                              : ''
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formMaxScore">
                        <Form.Label>Max eScore</Form.Label>
                        <Form.Control
                          required
                          type="number"
                          step="any"
                          name="maxEscore"
                          placeholder="Maxn eScore"
                          defaultValue={
                            !!props.editTrigger.jsonData &&
                            'max_escore' in props.editTrigger.jsonData &&
                            props.editTrigger.jsonData.max_escore !== ''
                              ? props.editTrigger.jsonData.max_escore
                              : ''
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formMinRank">
                        <Form.Label>Min SalesRank</Form.Label>
                        <Form.Control
                          required
                          type="number"
                          step="any"
                          name="minRank"
                          placeholder="Min SalesRank"
                          defaultValue={
                            props.editTrigger.jsonData &&
                            'min_rank' in props.editTrigger.jsonData &&
                            props.editTrigger.jsonData.min_rank !== ''
                              ? props.editTrigger.jsonData.min_rank
                              : ''
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formMaxRank">
                        <Form.Label>Max SalesRank</Form.Label>
                        <Form.Control
                          required
                          type="number"
                          step="any"
                          name="maxRank"
                          placeholder="Maxn SalesRank"
                          defaultValue={
                            props.editTrigger.jsonData &&
                            'max_rank' in props.editTrigger.jsonData &&
                            props.editTrigger.jsonData.max_rank !== ''
                              ? props.editTrigger.jsonData.max_rank
                              : ''
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formFbaSlot">
                        <Form.Label>FBA Slot</Form.Label>
                        <Form.Control
                          required
                          as="select"
                          name="fbaSlot"
                          defaultValue={
                            props.editTrigger.jsonData &&
                            'fba_slot' in props.editTrigger.jsonData &&
                            props.editTrigger.jsonData.fba_slot !== ''
                              ? props.editTrigger.jsonData.fba_slot
                              : ''
                          }
                        >
                          <option value="-1">SKIP</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </Form.Control>
                        {/* <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="fbaSlot"
                            placeholder="FBA Slot"
                            defaultValue={
                              "fba_slot" in props.editTrigger.jsonData &&
                              props.editTrigger.jsonData.fba_slot !== ""
                                ? props.editTrigger.jsonData.fba_slot
                                : ""
                            }
                          />
                          <Form.Text className="text-muted">
                            Enter <strong>-1</strong> to disable.
                          </Form.Text> */}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      {props.triggerCatName.includes('_new_') ? (
                        <Form.Group controlId="formUsedSlot">
                          <Form.Label>New Slot</Form.Label>
                          <Form.Control
                            required
                            as="select"
                            name="newSlot"
                            defaultValue={
                              props.editTrigger.jsonData &&
                              'new_slot' in props.editTrigger.jsonData &&
                              props.editTrigger.jsonData.new_slot !== ''
                                ? props.editTrigger.jsonData.new_slot
                                : ''
                            }
                          >
                            <option value="-1">SKIP</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">10</option>
                            <option value="7">15</option>
                          </Form.Control>
                        </Form.Group>
                      ) : (
                        <Form.Group controlId="formUsedSlot">
                          <Form.Label>Used Slot</Form.Label>
                          <Form.Control
                            required
                            as="select"
                            name="usedSlot"
                            defaultValue={
                              props.editTrigger.jsonData &&
                              'used_slot' in props.editTrigger.jsonData &&
                              props.editTrigger.jsonData.used_slot !== ''
                                ? props.editTrigger.jsonData.used_slot
                                : ''
                            }
                          >
                            <option value="-1">SKIP</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">10</option>
                            <option value="7">15</option>
                          </Form.Control>
                        </Form.Group>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formAmzPer">
                        <Form.Label>Amazon Off %</Form.Label>

                        <InputGroup className="mb-3">
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="amzOffPer"
                            defaultValue={
                              props.editTrigger.jsonData &&
                              'amz_off_per' in props.editTrigger.jsonData &&
                              props.editTrigger.jsonData.amz_off_per !== ''
                                ? props.editTrigger.jsonData.amz_off_per
                                : ''
                            }
                          />

                          <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formTargetProfit">
                        <Form.Label>Target Profit</Form.Label>

                        <InputGroup>
                          <InputGroup.Text
                            style={{
                              background: '#007bff',
                              color: '#fff',
                              fontWeight: 'bold',
                            }}
                            id="per-symbol"
                          >
                            $
                          </InputGroup.Text>

                          <Form.Control
                            style={{
                              background: '#007bff',
                              color: '#fff',
                              fontWeight: 'bold',
                            }}
                            required
                            type="number"
                            step="0.01"
                            name="targetProfit"
                            defaultValue={
                              props.editTrigger.jsonData &&
                              'target_profit' in props.editTrigger.jsonData &&
                              props.editTrigger.jsonData.target_profit !== ''
                                ? props.editTrigger.jsonData.target_profit
                                : ''
                            }
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row style={{ height: '50px' }}>
                    <Col style={{ paddingTop: '8px' }} md={3}>
                      <Form.Group controlId="formIsCeiling1Compare">
                        <Form.Check
                          onChange={handleC1Change}
                          type="switch"
                          name="isCeiling1Enabled"
                          id="is-ceiling-1"
                          defaultChecked={
                            props.editTrigger.jsonData &&
                            'ceiling_1_option' in props.editTrigger.jsonData &&
                            props.editTrigger.jsonData.ceiling_1_option !== ''
                          }
                          label="Enable Ceiling 1?"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      {isCeiling1Enabled && (
                        <Form.Group controlId="formCeiling1Options">
                          <Form.Control
                            as="select"
                            name="ceiling1Options"
                            defaultValue={props.editTrigger.jsonData && props.editTrigger.jsonData.ceiling_1_option}
                          >
                            <option value="">...</option>
                            <option value="Lowest New Price">Lowest New Price</option>
                            <option value="New Buy Box">New Buy Box</option>
                          </Form.Control>
                        </Form.Group>
                      )}
                    </Col>
                    <Col md={5}>
                      {isCeiling1Enabled && (
                        <InputGroup>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="ceiling1Discount"
                            defaultValue={
                              props.editTrigger.jsonData &&
                              'ceiling_1_discount' in props.editTrigger.jsonData &&
                              props.editTrigger.jsonData.ceiling_1_discount !== ''
                                ? props.editTrigger.jsonData.ceiling_1_discount
                                : ''
                            }
                          />

                          <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                        </InputGroup>

                        // <Form.Group controlId="formAmzPer">

                        // </Form.Group>
                      )}
                    </Col>
                  </Row>

                  <Row style={{ height: '50px' }}>
                    <Col style={{ paddingTop: '8px' }} md={2}>
                      <Form.Group controlId="formIsCeiling3Compare">
                        <Form.Check
                          onChange={handleC3Change}
                          type="switch"
                          name="isCeiling3Enabled"
                          defaultChecked={
                            props.editTrigger.jsonData &&
                            'ceiling_3_option' in props.editTrigger.jsonData &&
                            props.editTrigger.jsonData.ceiling_3_option !== ''
                          }
                          id="is-ceiling-3"
                          label="PrimeLess?"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      {isCeiling3Enabled && (
                        <Form.Group controlId="formCeiling3Options">
                          <Form.Control
                            style={{ fontSize: '13px' }}
                            as="select"
                            name="ceiling3Options"
                            defaultValue={props.editTrigger.jsonData && props.editTrigger.jsonData.ceiling_3_option}
                          >
                            <option>...</option>
                            <option>Lowest Used Offer</option>
                            <option>2nd Lowest Used Offer</option>
                            <option>3rd Lowest Used Offer</option>
                            <option>Average of 3 Used Offers</option>
                          </Form.Control>
                        </Form.Group>
                      )}
                    </Col>
                    <Col md={3}>
                      {isCeiling3Enabled && (
                        <InputGroup>
                          <InputGroup.Text id="per-symbol">$</InputGroup.Text>

                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="ceiling3BumpUpDollars"
                            defaultValue={
                              props.editTrigger.jsonData && 'ceiling_3_bump_up_dollars' in props.editTrigger.jsonData
                                ? props.editTrigger.jsonData.ceiling_3_bump_up_dollars
                                : ''
                            }
                            placeholder="Bump$"
                          />
                        </InputGroup>

                        // <Form.Group controlId="formAmzPer">

                        // </Form.Group>
                      )}
                    </Col>
                    <Col md={3}>
                      {isCeiling3Enabled && (
                        <InputGroup>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="ceiling3BumpUpPercentage"
                            defaultValue={
                              props.editTrigger.jsonData && 'ceiling_3_bump_up_percentage' in props.editTrigger.jsonData
                                ? props.editTrigger.jsonData.ceiling_3_bump_up_percentage
                                : 0
                            }
                            placeholder="Bump%"
                          />

                          <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                        </InputGroup>
                      )}
                    </Col>
                  </Row>

                  <Row style={{ height: '50px' }}>
                    <Col style={{ paddingTop: '8px', paddingRight: '0' }} md={2}>
                      <Form.Group controlId="formIsCeiling2Compare">
                        <Form.Check
                          onChange={handleC2Change}
                          type="switch"
                          name="isCeiling2Enabled"
                          id="is-ceiling-2"
                          label="Ceiling 2?"
                          defaultChecked={
                            props.editTrigger.jsonData &&
                            'ceiling_2_option' in props.editTrigger.jsonData &&
                            props.editTrigger.jsonData.ceiling_2_option !== ''
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      {isCeiling2Enabled && (
                        <Form.Group controlId="formCeiling2Options">
                          <Form.Control
                            as="select"
                            name="ceiling2Options"
                            style={{ fontSize: '13px' }}
                            defaultValue={props.editTrigger.jsonData && props.editTrigger.jsonData.ceiling_2_option}
                          >
                            <option>...</option>
                            <option>Lowest Used Offer</option>
                            <option>2nd Lowest Used Offer</option>
                            <option>3rd Lowest Used Offer</option>
                            <option>Average of 3 Used Offers</option>
                          </Form.Control>
                        </Form.Group>
                      )}
                    </Col>
                    <Col md={3}>
                      {isCeiling2Enabled && (
                        <InputGroup>
                          <InputGroup.Text id="per-symbol">$</InputGroup.Text>

                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="ceiling2BumpUpDollars"
                            defaultValue={
                              props.editTrigger.jsonData && 'ceiling_2_bump_up_dollars' in props.editTrigger.jsonData
                                ? props.editTrigger.jsonData.ceiling_2_bump_up_dollars
                                : ''
                            }
                            placeholder="Bump$"
                          />
                        </InputGroup>
                      )}
                    </Col>
                    <Col md={3}>
                      {isCeiling2Enabled && (
                        <InputGroup>
                          <Form.Control
                            required
                            type="number"
                            step="0.01"
                            name="ceiling2BumpUpPercentage"
                            defaultValue={
                              props.editTrigger.jsonData && 'ceiling_2_bump_up_percetange' in props.editTrigger.jsonData
                                ? props.editTrigger.jsonData.ceiling_2_bump_up_percetange
                                : ''
                            }
                            placeholder="Bump%"
                          />

                          <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                        </InputGroup>
                      )}
                    </Col>
                  </Row>

                  {props.premiumLevel === 1 &&
                    props.domainName !== 'admin.emptyshelves.com' &&
                    props.domainName !== 'admin.bookteriors.com' && (
                      <>
                        {!props.triggerCatName.includes('_ca_') && (
                          <Row style={{ height: '50px' }}>
                            <Col
                              style={{
                                paddingTop: '8px',
                                paddingRight: '10',
                              }}
                              md={3}
                            >
                              <Form.Group controlId="formIsCeilingKeepaCompare">
                                <Form.Check
                                  onChange={handleCKeepaChange}
                                  type="switch"
                                  name="isCeilingKeepaEnabled"
                                  id="is-ceiling-keepa"
                                  label="Keepa Ceiling?"
                                  defaultChecked={
                                    props.editTrigger.jsonData &&
                                    'ceiling_keepa_option' in props.editTrigger.jsonData &&
                                    props.editTrigger.jsonData.ceiling_keepa_option !== ''
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              {isCeilingKeepaEnabled && (
                                <Form.Group controlId="formCeiling2Options">
                                  <Form.Control
                                    style={{ fontSize: '13px' }}
                                    as="select"
                                    name="ceilingKeepaOptions"
                                    defaultValue={
                                      props.editTrigger.jsonData && 'ceiling_keepa_option' in props.editTrigger.jsonData
                                        ? props.editTrigger.jsonData.ceiling_keepa_option
                                        : ''
                                    }
                                  >
                                    <option>...</option>
                                    <option>Median</option>
                                    <option>Percentile 80</option>
                                    <option>Percentile 20</option>
                                  </Form.Control>
                                </Form.Group>
                              )}
                            </Col>
                            <Col md={3}>
                              {isCeilingKeepaEnabled && (
                                <InputGroup>
                                  <InputGroup.Text id="per-symbol">$</InputGroup.Text>

                                  <Form.Control
                                    placeholder="Bump$"
                                    required
                                    type="number"
                                    step="0.01"
                                    name="ceilingKeepaBumpUpDollars"
                                    defaultValue={
                                      props.editTrigger.jsonData &&
                                      'ceiling_keepa_bump_up_dollars' in props.editTrigger.jsonData
                                        ? props.editTrigger.jsonData.ceiling_keepa_bump_up_dollars
                                        : ''
                                    }
                                  />
                                </InputGroup>
                              )}
                            </Col>
                            <Col md={3}>
                              {isCeilingKeepaEnabled && (
                                <InputGroup>
                                  <Form.Control
                                    placeholder="Bump%"
                                    required
                                    type="number"
                                    step="0.01"
                                    name="ceilingKeepaBumpUpPercentage"
                                    defaultValue={
                                      props.editTrigger.jsonData &&
                                      'ceiling_keepa_bump_up_percentage' in props.editTrigger.jsonData
                                        ? props.editTrigger.jsonData.ceiling_keepa_bump_up_percentage
                                        : ''
                                    }
                                  />

                                  <InputGroup.Text id="per-symbol">%</InputGroup.Text>
                                </InputGroup>
                              )}
                            </Col>
                          </Row>
                        )}

                        <Row style={{ height: '50px' }}>
                          <Col md={5} style={{ paddingTop: '8px' }}>
                            <Form.Group controlId="formIsMMCompare">
                              <Form.Check
                                onChange={(evt) => {
                                  setIsMMEnabled(evt.target.checked);
                                }}
                                type="switch"
                                name="isMMEnabled"
                                id="is-mm"
                                defaultChecked={
                                  props.editTrigger.jsonData &&
                                  'is_mm' in props.editTrigger.jsonData &&
                                  props.editTrigger.jsonData.is_mm !== false
                                    ? Boolean(props.editTrigger.jsonData.is_mm)
                                    : false
                                }
                                label="Enable Marketplace Multiplier?"
                              />
                            </Form.Group>
                          </Col>

                          <Col md={7}>
                            {isMMEnabled && (
                              <InputGroup>
                                <Form.Control
                                  required
                                  type="number"
                                  step="0.01"
                                  name="mm_option"
                                  defaultValue={
                                    props.editTrigger.jsonData &&
                                    'mm_val' in props.editTrigger.jsonData &&
                                    props.editTrigger.jsonData.mm_val !== ''
                                      ? props.editTrigger.jsonData.mm_val
                                      : ''
                                  }
                                />

                                <InputGroup.Text id="per-symbol">X Target Profit</InputGroup.Text>
                              </InputGroup>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formIsBBCompare">
                        <Form.Check
                          type="switch"
                          name="isBBCompare"
                          id="bb-compare"
                          label="BB Compare?"
                          defaultChecked={props.editTrigger.jsonData && props.editTrigger.jsonData.bb_compare}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Check
                        type="switch"
                        name="isAlwaysReject"
                        id="always-reject"
                        label="Always Reject?"
                        defaultChecked={props.editTrigger.jsonData && props.editTrigger.jsonData.always_reject}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
