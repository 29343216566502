import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Table, Button } from 'react-bootstrap';
import 'react-confirm-alert/src/react-confirm-alert.css';

interface TableListTriggersProps {
  deleteTrigger: (trigger: Trigger, _index: number) => void;
  triggers: Trigger[];
  handleEditShow: (trigger: Trigger, _index: number) => void;
  triggerCatName: string;
}

interface Trigger {
  TriggerJson: string;
  Id: string;
  TriggerName: string;
}

export function TableListTriggers(props: TableListTriggersProps) {
  function deleteTrigger(trigger: Trigger, _index: number) {
    confirmAlert({
      title: 'Delete Trigger?',
      message: 'This will permanently delete this trigger. Are you sure to do ',
      buttons: [
        {
          label: 'Yes',
          onClick: () => props.deleteTrigger(trigger, _index),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  }

  return (
    <Table bordered className="table_triggers">
      <thead>
        <tr>
          <th style={{ width: '10%' }}>Trigger Name</th>
          <th>Min eScore</th>
          <th>Max eScore</th>
          <th>Min Salesrank</th>
          <th>Max Salesrank</th>
          <th>FBA Slot</th>
          <th>{props.triggerCatName.includes('_new_') ? 'New' : 'Used'} Slot</th>
          <th>BB Compare</th>
          <th>Amazon Off Percentage</th>
          <th>Target Profit</th>
          <th>Always Reject</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {props.triggers.length >= 1 &&
          props.triggers.map((trigger, _index) => {
            try {
              const triggerJson = JSON.parse(trigger.TriggerJson);
              return (
                <tr key={trigger.Id}>
                  <td>{trigger.TriggerName}</td>
                  <td>{triggerJson.min_escore}</td>
                  <td>{triggerJson.max_escore}</td>
                  <td>{triggerJson.min_rank}</td>
                  <td>{triggerJson.max_rank}</td>
                  <td>{parseInt(triggerJson.fba_slot) >= 0 ? triggerJson.fba_slot : 'Skip'}</td>
                  <td>
                    {props.triggerCatName.includes('_new_')
                      ? triggerJson.new_slot
                      : parseInt(triggerJson.used_slot) >= 0
                      ? triggerJson.used_slot
                      : 'Skip'}
                  </td>
                  <td>{triggerJson.bb_compare === true ? 'YES' : 'NO'}</td>
                  <td>{triggerJson.amz_off_per}</td>
                  <td>{triggerJson.target_profit}</td>
                  <td>{triggerJson.always_reject === true ? 'YES' : 'NO'}</td>
                  <td style={{ width: '13%' }}>
                    <Button
                      size="sm"
                      className="actionButtonLessPadding"
                      variant="secondary"
                      onClick={() => props.handleEditShow(trigger, _index)}
                    >
                      Edit
                    </Button>
                    <Button
                      size="sm"
                      className="actionButtonLessPadding"
                      style={{ marginLeft: '8px' }}
                      variant="danger"
                      onClick={() => deleteTrigger(trigger, _index)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            } catch (e) {
              return (
                <tr>
                  <td>{trigger.TriggerName}</td>
                  <td style={{ textAlign: 'center' }} colSpan={12}>
                    Invalid Trigger
                  </td>
                </tr>
              );
            }
          })}
      </tbody>
    </Table>
  );
}
