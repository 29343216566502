import React from 'react';
import { Modal, Table } from 'react-bootstrap';
import { useState } from 'react';
import { callApi } from '../utils/helper';
import Lottie from 'lottie-react';
import animationData from '../assets/animations/9329-loading.json';

const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export interface Feed {
  success_count: string;
  last_checked: string;
  status: string;
  id: string;
  submission_id: string;
  total_items: string;
  submitted_on: string;
}
interface ModalRepricedItemsProps {
  showRepricedItemsModal: boolean;
  handleClose: () => void;
  selectedFeed?: Feed;
  userData: {
    id: string;
    token: string;
  };
}

interface Item {
  sku: string;
  old_price: string;
  new_price: string;
  reprice_type: string | null;
}

export function ModalRepricedItems(props: ModalRepricedItemsProps) {
  const [itemsList, setItemList] = useState<Item[]>([]);
  const [loadingItemsList, setLoadingItemsList] = useState(true);

  function handleOpen() {
    if (props?.selectedFeed?.id) {
      setItemList([]);
      setLoadingItemsList(true);
      callApi(
        `inventory/history/items/${props.selectedFeed.id}`,
        'POST',
        {
          user_id: props.userData.id,
          app_token: props.userData.token,
        },
        (response) => {
          setLoadingItemsList(false);
          if ('status' in response && response.status === true) setItemList(response.data);
        }
      );
    }
  }
  if (!props.selectedFeed) return null;

  return (
    <>
      <Modal
        onEntered={handleOpen}
        show={props.showRepricedItemsModal}
        onHide={props.handleClose}
        dialogClassName="editModal"
      >
        <Modal.Header closeButton>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <h3>#{props.selectedFeed.submission_id}</h3>

            <div>
              <b>{props.selectedFeed.total_items}</b> items submitted on{' '}
              {new Date(props.selectedFeed.submitted_on).toLocaleString()}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {/* <div style={{ textAlign: "center", marginBottom: "10px" }}>
              <Button
                variant="danger"
                type="button"
                style={{ borderRadius: 0 }}
                onClick={() => props.undoReprice()}
                disabled={props.isUndoingFeed}
              >
                {props.isUndoingFeed === true ? (
                  <Spinner animation="border" role="status"></Spinner>
                ) : (
                  <span>Undo this reprice</span>
                )}
              </Button>
              <Button
                variant="primary"
                type="button"
                style={{ borderRadius: 0, marginLeft: "20px" }}
                onClick={() => props.downloadCSV()}
                disabled={props.isDownloadingCSV}
              >
                {props.isDownloadingCSV === true ? (
                  <Spinner animation="border" role="status"></Spinner>
                ) : (
                  <span>Download CSV</span>
                )}
              </Button>
            </div> */}

          <div style={{ marginTop: '0px' }}>
            {loadingItemsList === true ? (
              <div>
                <Lottie {...defaultLottieOptions} height={400} width={400} />
              </div>
            ) : (
              <Table bordered>
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th style={{ textAlign: 'center' }}>Old Price</th>
                    <th style={{ textAlign: 'center' }}>New Price</th>
                    <th style={{ textAlign: 'center' }}>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {itemsList.map((item) => {
                    return (
                      <tr>
                        <td>{item.sku}</td>
                        <td style={{ textAlign: 'center' }}>${item.old_price}</td>
                        <td style={{ textAlign: 'center' }}>${item.new_price}</td>
                        <td style={{ textAlign: 'center' }}>
                          {item.reprice_type === null || item.reprice_type === '' ? 'N/A' : item.reprice_type}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </div>
        </Modal.Body>

        {/* <Modal.Footer>
            
          </Modal.Footer> */}
      </Modal>
    </>
  );
}
