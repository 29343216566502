import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import auth from '../utils/auth';
import { Spinner } from 'react-bootstrap';

interface ProtectedRouteProps {
  element: JSX.Element;
}

export function ProtectedRoute({ element }: ProtectedRouteProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  async function validateToken() {
    const isAuthenticated = await auth.isAuthenticated();
    setIsLoading(false);
    setIsLoggedIn(isAuthenticated);
  }

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const urlToken = params.get('token');
    if (urlToken !== '' && urlToken !== undefined && urlToken !== null) {
      auth.setAdminData({
        ...auth.getAdminData(),
        token: urlToken,
      });

      (window as Window).location = window.location.href.split('?')[0];
    }

    validateToken();
  }, []);

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center', fontSize: '34px', marginTop: '50px' }}>
        <Spinner
          animation="border"
          role="status"
          style={{ marginRight: '10px', width: '40px', height: '40px' }}
        ></Spinner>{' '}
        Loading ...
      </div>
    );
  }

  return isLoggedIn ? element : <Navigate to="/" />;
}
