export const domainName = window.location.hostname;

export const versionId = 40;
export const loginPageEmail = 'support@nuprice.co';
export const loginPageLogo = 'nuprice-logo.png';
export const loginPageBanner = 'nuprice-banner.png';
export const logoWidth = 150;

export const apiBaseUrlLocal =
  process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api/' : process.env.REACT_APP_API_BASE_URL;
