import React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Spinner, Alert, Container } from 'react-bootstrap';
import '../assets/style/login.css';
import { submitLogin } from '../utils/helper';
import ReCAPTCHA from 'react-google-recaptcha';
import auth from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import { DiscontinueBanner } from '../components/DiscontinueBanner';

interface LoginProps {}
type LoginFormEvent = React.FormEvent<HTMLFormElement> & {
  target: HTMLFormElement & {
    elements: {
      user_email: {
        value: string;
      };
      user_password: {
        value: string;
      };
    };
  };
};

export default function Login(props: LoginProps) {
  const [logingin, setLogingin] = useState<boolean>(false);
  const [isInvalidLogin, setisInvalidLogin] = useState<number>(-1);
  const [invalidLoginMessage, setInvalidLoginMessage] = useState<string>('');
  const [isNewSignup, setisNewSignup] = useState<boolean>(false);
  const [captchaToken, setCaptchaToken] = useState<string>('');
  const navigate = useNavigate();

  async function handleLogin(event: LoginFormEvent) {
    event.preventDefault();

    const user_email = event.target.elements.user_email.value;
    const user_password = event.target.elements.user_password.value;

    setisInvalidLogin(0);
    setInvalidLoginMessage('');

    if (!user_email || !user_password) {
      setisInvalidLogin(1);
      setInvalidLoginMessage('Missing credentials');
    } else if (!captchaToken && process.env.NODE_ENV !== 'development') {
      setisInvalidLogin(1);
      setInvalidLoginMessage('Invalid Captcha');
    } else {
      setLogingin(true);
      const response = await submitLogin(user_email, user_password, captchaToken);
      setLogingin(false);

      if (response.status === false) {
        setisInvalidLogin(1);
        setInvalidLoginMessage(response.error);
      } else {
        console.log(response);
        auth.login(response, () => {
          navigate('/home');
        });
      }
    }
  }

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const isNewSignup = params.get('signup');
    if (isNewSignup !== null) setisNewSignup(true);
  }, []);

  return (
    <Container fluid={true}>
      <DiscontinueBanner />
      <Row>
        <Col md={12}>
          <div className="div-form-container">
            <div style={{ borderBottom: '1px solid #ddd', paddingBottom: '20px' }}>
              <div>
                <div style={{ paddingLeft: '5px' }}>
                  <img width={150} alt="SCOUTIQ" src={require('../assets/images/nuprice-logo-dark.png')} />
                </div>
                <div
                  style={{
                    fontSize: '32px',
                    paddingTop: '15px',
                    fontWeight: 'bold',
                  }}
                >
                  <span style={{ color: '' }}>REPRICE</span>
                  <span style={{ color: '#E48B45' }}>IQ</span>
                </div>
              </div>
            </div>

            {isInvalidLogin === 1 && (
              <div style={{ marginTop: '20px' }}>
                <Alert variant="danger">
                  <span>{invalidLoginMessage}</span>
                </Alert>
              </div>
            )}

            {isNewSignup === true && (
              <Alert style={{ marginTop: '20px' }} key="new-user-signup" variant="success">
                Your account has been created successfully!
                <br />
                Please proceed to login.
              </Alert>
            )}

            <div style={{ marginTop: '35px' }}>
              <Form onSubmit={handleLogin}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    style={{ textAlign: 'center' }}
                    type="text"
                    name="user_email"
                    placeholder="Enter email"
                  />
                </Form.Group>

                <Form.Group style={{ marginTop: '30px' }} controlId="formBasicPassword">
                  <Form.Control
                    style={{ textAlign: 'center' }}
                    type="password"
                    name="user_password"
                    placeholder="Password"
                  />
                </Form.Group>

                {logingin ? (
                  <Spinner style={{ marginTop: '33px' }} animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  <div>
                    {process.env.NODE_ENV !== 'development' && (
                      <div style={{ paddingTop: '20px' }}>
                        <ReCAPTCHA
                          style={{ display: 'flex', justifyContent: 'center' }}
                          sitekey="6LeFuVMpAAAAAM8cf_fe9IpGGAjHOU_d7B7gyjAA"
                          onChange={(val: string | null) => {
                            setCaptchaToken(val as string);
                          }}
                        />
                      </div>
                    )}

                    <Button variant="primary" type="submit" style={{ marginTop: '30px' }}>
                      Submit
                    </Button>
                  </div>
                )}
              </Form>
            </div>

            <div className="support-msg" style={{ marginTop: '20px' }}>
              <Button
                onClick={() => {
                  navigate('forgot-password');
                }}
                style={{ padding: '0', color: '#DC3545' }}
                variant="link"
              >
                Forgot Password
              </Button>
            </div>

            <div style={{ marginTop: '20px' }}>
              <Button variant="link" onClick={() => navigate('/signup')}>
                Don't have an account? Click here to create one.
              </Button>
            </div>

            <div style={{ marginTop: '20px' }}>
              Questions? Email <a href="mailto:support@nuprice.co">support@nuprice.co</a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
